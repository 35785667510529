.container {
  height: 100vh;
  background-color: #f5f7fa;
  padding-top: 82px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.map {
  height: 80vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.city {
  height: 80vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ankara {
  width: 400px;
  height: max-content;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -70px;
}
.arrow {
  width: 42px;
  height: 48px;
}
@media screen and (max-width: 768px) {
  .container {
    height: max-content;
    width: 100%;
    background-color: #f5f7fa;
    padding-top: 82px;
    display: flex;

    align-items: center;
    flex-direction: column;
  }
  .map {
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .city {
    height: max-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon {
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .ankara {
    width: 400px;
    height: max-content;
    transform: translateX(50px);
  }
  .all {
    scale: 1.3;
  }
  .adana {
    transform: translateX(100px);
  }
  .adiyaman {
    transform: translateX(60px);
  }
  .afyonkarahisar {
    transform: translateX(70px);
  }
  .agri {
    transform: translateX(120px);
  }
  .aksaray {
    transform: translateX(120px);
  }
  .amasya {
    transform: translateX(70px);
  }
  .antalya {
    transform: translateX(70px);
  }
  .ardahan {
    transform: translateX(120px);
  }
  .artvin {
    transform: translateX(120px);
  }
  .aydin {
    transform: translateX(70px);
  }
  .balikesir {
    transform: translateX(70px);
  }
  .bartin {
    transform: translateX(70px);
  }
  .batman {
    transform: translateX(170px);
  }
  .bayburt {
    transform: translateX(120px);
  }
  .bilecik {
    transform: translateX(120px);
  }
  .bingol {
    transform: translateX(120px);
  }
  .bitlis {
    transform: translateX(120px);
  }
  .bolu {
    transform: translateX(80px);
  }
  .burdur {
    transform: translateX(100px);
  }
  .bursa {
    transform: translateX(80px);
  }
  .canakkale {
    transform: translateX(80px);
  }
  .cankiri {
    transform: translateX(80px);
  }
  .corum {
    transform: translateX(100px);
  }
  .denizli {
    transform: translateX(100px);
  }
  .diyarbakir {
    transform: translateX(100px);
  }
  .duzce {
    transform: translateX(100px);
  }
  .edirne {
    transform: translateX(150px);
  }
  .elazig {
    transform: translateX(50px);
  }
  .erzincan {
    transform: translateX(70px);
  }
  .erzurum {
    transform: translateX(70px);
  }
  .eskisehir {
    transform: translateX(70px);
  }
  .gaziantep {
    transform: translateX(70px);
  }
  .giresun {
    transform: translateX(100px);
  }
  .gumushane {
    transform: translateX(100px);
  }
  .hakkari {
    transform: translateX(100px);
  }
  .hatay {
    transform: translateX(150px);
  }
  .igdir {
    transform: translateX(70px);
  }
  .isparta {
    transform: translateX(70px);
  }
  .istanbul {
    transform: translateX(70px);
  }
  .izmir {
    transform: translateX(100px);
  }
  .kahramanmaras {
    transform: translateX(100px);
  }
  .karabuk {
    transform: translateX(100px);
  }
  .karaman {
    transform: translateX(150px);
  }
  .kars {
    transform: translateX(70px);
  }
  .kastamonu {
    transform: translateX(70px);
  }
  .kayseri {
    transform: translateX(70px);
  }
  .kilis {
    transform: translateX(20px);
  }
  .kirikkale {
    transform: translateX(100px);
  }
  .kirklareli {
    transform: translateX(60px);
  }
  .kirsehir {
    transform: translateX(60px);
  }
  .kocaeli {
    transform: translateX(60px);
  }
  .konya {
    transform: translateX(60px);
  }
  .kutahya {
    transform: translateX(60px);
  }
  .malatya {
    transform: translateX(60px);
  }
  .manisa {
    transform: translateX(60px);
  }
  .mugla {
    transform: translateX(60px);
  }
  .mus {
    transform: translateX(60px);
  }
  .nevsehir {
    transform: translateX(100px);
  }
  .nigde {
    transform: translateX(100px);
  }
  .osmaniye {
    transform: translateX(100px);
  }
  .rize {
    transform: translateX(100px);
  }
  .sakarya {
    transform: translateX(100px);
  }
  .samsun {
    transform: translateX(70px);
  }
  .siirt {
    transform: translateX(70px);
  }
  .sinop {
    transform: translateX(70px);
  }
  .sivas {
    transform: translateX(70px);
  }
  .usak {
    transform: translateX(70px);
  }
  .van {
    transform: translateX(100px);
  }
  .yozgat {
    transform: translateX(60px);
  }
  .zonguldak {
    transform: translateX(60px);
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .ankara {
    width: 300px;
    height: max-content;
    transform: translateX(50px);
  }
  .all {
    scale: 1.3;
  }
  .adana {
    transform: translateX(50px);
  }
  .adiyaman {
    transform: translateX(30px);
  }
  .afyonkarahisar {
    transform: translateX(30px);
  }
  .agri {
    transform: translateX(60px);
  }
  .aksaray {
    transform: translateX(60px);
  }
  .amasya {
    transform: translateX(30px);
  }
  .antalya {
    transform: translateX(30px);
  }
  .ardahan {
    transform: translateX(60px);
  }
  .artvin {
    transform: translateX(60px);
  }
  .aydin {
    transform: translateX(20px);
  }
  .balikesir {
    transform: translateX(40px);
  }
  .bartin {
    transform: translateX(30px);
    scale: 1.1;
  }
  .batman {
    transform: translateX(100px);
  }
  .bayburt {
    transform: translateX(60px);
  }
  .bilecik {
    transform: translateX(70px);
  }
  .bingol {
    transform: translateX(50px);
  }
  .bitlis {
    transform: translateX(50px);
  }
  .bolu {
    transform: translateX(20px);
  }
  .burdur {
    transform: translateX(40px);
  }
  .bursa {
    transform: translateX(30px);
  }
  .canakkale {
    transform: translateX(30px);
  }
  .cankiri {
    transform: translateX(40px);
  }
  .corum {
    transform: translateX(50px);
  }
  .denizli {
    transform: translateX(50px);
  }
  .diyarbakir {
    transform: translateX(40px);
  }
  .duzce {
    transform: translateX(40px);
  }
  .edirne {
    transform: translateX(80px);
  }
  .elazig {
    transform: translateX(20px);
  }
  .erzincan {
    transform: translateX(20px);
  }
  .erzurum {
    transform: translateX(30px);
  }
  .eskisehir {
    transform: translateX(20px);
  }
  .gaziantep {
    transform: translateX(10px);
  }
  .giresun {
    transform: translateX(50px);
  }
  .gumushane {
    transform: translateX(50px);
  }
  .hakkari {
    transform: translateX(40px);
  }
  .hatay {
    transform: translateX(80px);
  }
  .igdir {
    transform: translateX(20px);
  }
  .isparta {
    transform: translateX(20px);
  }
  .istanbul {
    transform: translateX(30px);
  }
  .izmir {
    transform: translateX(50px);
  }
  .kahramanmaras {
    transform: translateX(60px);
  }
  .karabuk {
    transform: translateX(50px);
  }
  .karaman {
    transform: translateX(60px);
  }
  .kars {
    transform: translateX(50px);
  }
  .kastamonu {
    transform: translateX(30px);
  }
  .kayseri {
    transform: translateX(20px);
    scale: 1.1;
  }
  .kirikkale {
    transform: translateX(80px);
  }
  .kirklareli {
    transform: translateX(30px);
    scale: 1.1;
  }
  .kirsehir {
    transform: translateX(30px);
  }
  .kocaeli {
    transform: translateX(30px);
  }
  .konya {
    transform: translateX(30px);
    scale: 1.1;
  }
  .kutahya {
    transform: translateX(30px);
    scale: 1.1;
  }
  .malatya {
    transform: translateX(30px);
    scale: 1.1;
  }
  .manisa {
    transform: translateX(20px);
    scale: 1.1;
  }
  .mugla {
    transform: translateX(30px);
  }
  .mus {
    transform: translateX(30px);
  }
  .nevsehir {
    transform: translateX(50px);
  }
  .nigde {
    transform: translateX(50px);
  }
  .osmaniye {
    transform: translateX(50px);
    scale: 1.1;
  }
  .rize {
    transform: translateX(50px);
    scale: 1.1;
  }
  .sakarya {
    transform: translateX(50px);
    scale: 1.1;
  }
  .siirt {
    transform: translateX(30px);
  }
  .sinop {
    transform: translateX(30px);
  }
  .sirnak {
    transform: translateX(10px);
    scale: 1.1;
  }
  .sivas {
    transform: translateX(30px);
    scale: 1.1;
  }
  .usak {
    transform: translateX(30px);
    scale: 1.1;
  }
  .van {
    transform: translateX(50px);
  }
  .yozgat {
    transform: translateX(10px);
    scale: 1.1;
  }
  .zonguldak {
    transform: translateX(30px);
  }
}
