.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.card {
  width: 570px;
  height: 650px;
  z-index: 4;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  margin-top: 82px;
}
.header {
  width: 100%;
  height: 10%;
  background-color: #00abcd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: aliceblue;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_p {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  padding-left: 16px;
}
.date_container {
  width: 160px;
  height: 80px;
  background-color: #f1f1f1;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 24px;
  text-align: center;
  border-radius: 4px;
}
.date {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 22px;
}
.date_header {
  text-align: center;
  font-family: "Inter", sans-serif;
}
.conclusion_container {
  width: 450px;
  height: 200px;
  background-color: #f1f1f1;
  margin: 0 auto;
  margin-top: 24px;
}
.conclusion_header {
  position: relative;
  right: 15px;
  top: 15px;
  background-color: #00abcd;
  width: fit-content;
  height: fit-content;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 4px;
}
.conclusion {
  padding: 16px;
  margin-top: 8px;
  height: 135px;
  text-align: justify;
  overflow-y: scroll;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.note_container {
  width: 450px;
  height: 200px;
  background-color: #f1f1f1;
  margin: 0 auto;
  margin-top: 24px;
}
.note_header {
  position: relative;
  right: 15px;
  top: 15px;
  background-color: #00abcd;
  width: fit-content;
  height: fit-content;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 4px;
}
.note {
  padding: 16px;
  margin-top: 8px;
  height: 135px;
  text-align: justify;
  overflow-y: scroll;
  font-family: "Inter", sans-serif;
  font-size: 14px;
}
.button {
  margin-top: 16px;
  width: 120px;
  background-color: transparent;
  padding: 8px;
  border-radius: 4px;
}
.button_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::-webkit-scrollbar {
  background-color: #d9d9d9;
  width: 10px;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  border-radius: 8px;
}

@media screen and (max-width: 320px) {
  .card {
    width: 300px;
    height: 450px;
    z-index: 4;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 82px;
  }
  .header {
    width: 100%;
    height: 10%;
    background-color: #00abcd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: aliceblue;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_p {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
  }
  .date_container {
    width: 160px;
    height: 60px;
    background-color: #f1f1f1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    text-align: center;
    border-radius: 4px;
  }
  .date {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .date_header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
  .conclusion_container {
    width: 260px;
    height: 140px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 16px;
  }
  .conclusion_header {
    position: relative;
    right: 15px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
  }
  .conclusion {
    padding: 16px;
    margin-top: 8px;
    height: 100px;
    text-align: justify;
    overflow-y: scroll;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .note_container {
    width: 260px;
    height: 140px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 16px;
  }
  .note_header {
    position: relative;
    right: 15px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border-radius: 4px;
  }
  .note {
    padding: 16px;
    margin-top: 8px;
    height: 100px;
    text-align: justify;
    overflow-y: scroll;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .button {
    margin-top: 16px;
    width: 120px;
    background-color: transparent;
    padding: 8px;
    border-radius: 4px;
  }
  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::-webkit-scrollbar {
    background-color: #d9d9d9;
    width: 10px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #b4b4b4;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .card {
    width: 330px;
    height: 450px;
    z-index: 4;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 82px;
  }
  .header {
    width: 100%;
    height: 10%;
    background-color: #00abcd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: aliceblue;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_p {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
  }
  .date_container {
    width: 160px;
    height: 60px;
    background-color: #f1f1f1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    text-align: center;
    border-radius: 4px;
  }
  .date {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .date_header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
  .conclusion_container {
    width: 280px;
    height: 140px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 16px;
  }
  .conclusion_header {
    position: relative;
    right: 15px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
  }
  .conclusion {
    padding: 16px;
    margin-top: 8px;
    height: 100px;
    text-align: justify;
    overflow-y: scroll;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .note_container {
    width: 280px;
    height: 140px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 16px;
  }
  .note_header {
    position: relative;
    right: 15px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border-radius: 4px;
  }
  .note {
    padding: 16px;
    margin-top: 8px;
    height: 100px;
    text-align: justify;
    overflow-y: scroll;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .button {
    margin-top: 16px;
    width: 120px;
    background-color: transparent;
    padding: 8px;
    border-radius: 4px;
  }
  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::-webkit-scrollbar {
    background-color: #d9d9d9;
    width: 10px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #b4b4b4;
    border-radius: 8px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .card {
    width: 400px;
    height: 450px;
    z-index: 4;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    margin-top: 82px;
  }
  .header {
    width: 100%;
    height: 10%;
    background-color: #00abcd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: aliceblue;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_p {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
  }
  .date_container {
    width: 160px;
    height: 60px;
    background-color: #f1f1f1;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    text-align: center;
    border-radius: 4px;
  }
  .date {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
  }
  .date_header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-weight: 600;
  }
  .conclusion_container {
    width: 330px;
    height: 140px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 16px;
  }
  .conclusion_header {
    position: relative;
    right: 15px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 4px;
    padding-right: 4px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
  }
  .conclusion {
    padding: 16px;
    margin-top: 8px;
    height: 100px;
    text-align: justify;
    overflow-y: scroll;
    font-family: "Inter", sans-serif;
    font-size: 13px;
  }
  .note_container {
    width: 330px;
    height: 140px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 16px;
  }
  .note_header {
    position: relative;
    right: 15px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 4px;
    padding-right: 4px;
    font-size: 14px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border-radius: 4px;
  }
  .note {
    padding: 16px;
    margin-top: 8px;
    height: 100px;
    text-align: justify;
    overflow-y: scroll;
    font-family: "Inter", sans-serif;
    font-size: 13px;
  }
  .button {
    margin-top: 16px;
    width: 120px;
    background-color: transparent;
    padding: 8px;
    border-radius: 4px;
  }
  .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ::-webkit-scrollbar {
    background-color: #d9d9d9;
    width: 10px;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #b4b4b4;
    border-radius: 8px;
  }
}
