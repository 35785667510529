.ad{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 970px;
    height: 250px;
    background-color: green;
    margin: 0 auto;
}
@media (max-width: 768px) {
    .ad {
      background-color: green;
      width: 300px;
      height: 250px;
      margin-top: 16px;
      margin-bottom: 16px;
    }
}

  