
@media only screen and (max-width: 767px) {
  .container {
    width: 95%;
    margin: 0 auto;
    margin-top: 82px;
  }
  .card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .blog_image_container {
    width: 95%;

    margin: 0 auto;
  }
  .blog_image {
    width: 100%;
    border-radius: 8px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .card_upper {
    height: 10%;
    font-size: 14px;
  }
  .info {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .time {
    display: flex;
  }
  .author {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;   
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .author_name {
    margin-left: 4px;
    font-family: "Inter", sans-serif;
  }
  .update {
    
  }
  .clock {
    margin-left: 4px;
  }
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
  }
  .avg_read_minute {
    margin-left: 4px;
    font-family: "Inter", sans-serif;
  }
  .tags {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tag {
    padding: 4px;
    background-color: #e2f1ff;
    color: #0085ff;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #0085ff;
    margin-left: 4px;
  }
  .article_title {
    font-family: "Inter", sans-serif;
    font-size: 18px;
    margin-left: 8px;
    margin-top: 16px;
  }
  .article {
    font-family: "Inter", sans-serif;
    margin-top: 16px;
    margin-left: 12px;
    margin-right: 8px;
    text-align: justify;
    height: 500px;
    overflow-y: scroll;
    color: #535659;
    line-height: 20px;
    font-size: 14px;
  }
  .article::-webkit-scrollbar {
    background-color: transparent;
  }
  .article::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-left: 4px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 75%;
    margin: 0 auto;
    margin-top: 82px;
  }
  .card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 32px;
  }
  .blog_image_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog_image {
    width: 85%;
    border-radius: 8px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .card_upper {
    height: 10%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
   
  }
  .info {
   
  }
  .time {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 8px;
  }
  .author {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;   
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .author_name {
   margin-left: 8px;
  }
  .update {
    
  }
  .clock {
    color: #535659;
    margin-left: 4px;
  }
  .social {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .avg_read_minute {
    margin-left: 4px;
  }
  .tags {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  }
  .tag {
    padding: 4px;
    background-color: #e2f1ff;
    color: #0085ff;
    font-family: "Inter", sans-serif;
    font-size: 18px;   
    border-radius: 4px;
    border: 1px solid #0085ff;
    margin-left: 4px;
  }
  .article_title {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    margin-left: 16px;
    margin-top: 16px;
  }
  .article {
    font-family: "Inter", sans-serif;
    margin-top: 16px;
    margin-left: 20px;
    margin-right: 16px;
    text-align: justify;
    height: 500px;
    overflow-y: scroll;
    color: #535659;
    line-height: 20px;
    font-size: 18px;
  }
  .article::-webkit-scrollbar {
    background-color: transparent;
  }
  .article::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 8px;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .container {
    width: 75%;
    margin: 0 auto;
    margin-top: 82px;
  }
  .card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 16px;
  }
  .blog_image_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .blog_image {
    width: 100%;
    border-radius: 8px;
    
   
  }
  .card_upper {
    height: 10%;
    display: flex;
    width: 85%;
    
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .info {
   display: flex;
  }
  .author {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .time{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }
  .author_name {
   margin-left: 8px;
  }
  .update {
   
  }
  .clock {
    margin-left: 4px;
  }
  .social {
    
  }
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 4px;
  }
  .avg_read_minute {
    margin-left: 4px;
  }
  .tags {
    margin: 0 auto;
    margin-top: 16px;
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    
  }
  .tag {
    padding: 8px;
    background-color: #e2f1ff;
    color: #0085ff;
    width: max-content;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #0085ff;
    margin-right: 16px;
    
  }
  .article_title {
    font-family: "Inter", sans-serif;
    font-size: x-large;
    margin-left: 32px;
    margin-top: 16px;
  }
  .article {
    font-family: "Inter", sans-serif;
    margin-top: 16px;
    margin-left: 32px;
    margin-right: 32px;
    margin-bottom: 32px;
    padding-bottom: 32px;
    text-align: justify;
    height: 500px;
    overflow-y: scroll;
    color: #535659;
    line-height: 20px;
  }
  .article::-webkit-scrollbar {
    background-color: transparent;
  }
  .article::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

}
@media only screen and (min-width: 1440px) {
  .container {
    width: 60%;
    margin: 0 auto;
    margin-top: 82px;
  }
  .card {
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    margin-bottom: 64px;
    margin-top: 182px;
  }
  .blog_image_container {
    margin: 0 auto;
  }
  .blog_image {
    width: 100%;
    height: 568px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .card_upper {
    height: 10%;
    display: flex;
    width: 85%;
    
    margin: 0 auto;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .info {
   display: flex;
  }
  .author {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .time{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
  }
  .author_name {
   margin-left: 16px;
  }
  .update {
   
  }
  .clock {
    margin-left: 8px;
  }
  .social {
    
  }
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 8px;
  }
  .avg_read_minute {
    margin-left: 8px;
  }
  .tags {
    margin: 0 auto;
    margin-top: 16px;
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    
  }
  .tag {
    padding: 8px;
    background-color: #e2f1ff;
    color: #0085ff;
    width: max-content;
    margin-top: 8px;
    border-radius: 4px;
    border: 1px solid #0085ff;
    margin-right: 16px;
    
  }
  .article_title {
    margin-top: 32px;
    margin-left: 64px;
    font-family: "Inter", sans-serif;
  }
  .article {
    font-family: "Inter", sans-serif;
    margin-top: 32px;
    margin-left: 64px;
    margin-right: 64px;
    text-align: justify;
    padding-bottom: 64px;
    color: #535659;
    line-height: 20px;
  }
 
}
