

@media only screen and (max-width: 374px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: x-large;
    margin-top: 16px;
  }
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
  }
  .card {
    width: 300px;
    height: 500px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .article_picture {
    width: 100%;
    height: 40%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .article_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }
  .article {
    position: relative;
    height: 30%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
    max-lines: 4;
    color: #535659;
  }

  .time {
    margin-left: 49px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 14px;
    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 13px;
    height: 13px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: x-large;
    margin-top: 16px;
  }
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
  }
  .card {
    width: 355px;
    height: 500px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .article_picture {
    width: 100%;
    height: 40%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .article_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }
  .article {
    position: relative;
    height: 30%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 15px;
    line-height: 20px;
    max-lines: 4;
    color: #535659;
  }

  .time {
    margin-left: 49px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 15px;
    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 14px;
    height: 14px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: x-large;
    margin-top: 16px;
  }
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
  }
  .card {
    width: 400px;
    height: 600px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .article_picture {
    width: 100%;
    height: 40%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .article_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 20px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }
  .article {
    position: relative;
    height: 30%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 20px;
    max-lines: 4;
    color: #535659;
  }

  .time {
    margin-left: 52px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 16px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    margin-left: 4px;
    width: 14px;
    height: 14px;
    color: #afafaf;
  }
  .author_picture {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 16px;

    width: 500px;
  }
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    padding-top: 32px;
  }
  .card {
    width: 500px;
    height: 650px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .article_picture {
    width: 100%;
    height: 40%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .article_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 20px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }
  .article {
    position: relative;
    height: 30%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 20px;
    max-lines: 4;
    color: #535659;
  }

  .time {
    margin-left: 52px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 15%;
    font-size: 14px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    margin-left: 4px;
    width: 14px;
    height: 14px;
    color: #afafaf;
  }
  .author_picture {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 16px;
    padding-left: 0px;
    width: 984px;
    text-align: left;
  }
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    gap: 8px;
    padding-top: 32px;
  }
  .card {
    width: 320px;
    height: 500px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .article_picture {
    width: 100%;
    height: 40%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .article_title {
    width: 100%;
    height: 15%;
    text-align: left;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    font-size: larger;
    overflow: hidden;
    margin-top: 8px;
    padding-top: 8px;
  }
  .article {
    width: 100%;
    height: 20%;
    padding-left: 16px;
    padding-right: 16px;

    margin-bottom: 32px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    overflow: hidden;
    line-height: 20px;
    color: #535659;
  }

  .time {
    margin-left: 52px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 16px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    margin-left: 4px;
    width: 14px;
    height: 14px;
    color: #afafaf;
  }
  .author_picture {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
}
@media screen and (min-width: 1440px) {
  .container {
    width: max-content;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 16px;
    text-align: left;

    width: max-content;
  }
  .card_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: max-content;
    gap: 8px;
    padding-top: 32px;
  }
  .card {
    width: 370px;
    height: 500px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .article_picture {
    width: 100%;
    height: 40%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .article_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }
  .article {
    position: relative;
    height: 30%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
    max-lines: 4;
    color: #535659;
  }

  .time {
    margin-left: 49px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 14px;
    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 13px;
    height: 13px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
}
