.card {
  width: 400px;
  height: 260px;
  border: 2px solid black;
  background-color: white;
  border-radius: 8px;
}
.card_content {
  margin-left: 8px;
}
.header {
  margin-left: 8px;
  margin-top: 16px;
}
.mayor_photo {
  position: relative;
  float: right;
  top: -50px;
  left: 50px;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid black;
}

.party_logo {
  width: 70px;
  height: 70px;
  padding: 4px;
  margin-left: 4px;
  border: 2px solid black;
  background-size: contain;
  border-radius: 4px;
}
.mayor_name {
  font-family: "Inter", sans-serif;
  width: 100%;
  font-size: 24px;
  font-weight: 800;  
  margin-left: 8px;
}
.mayor_party {
  font-family: "Inter", sans-serif;
  font-size: large;
  font-weight: 500;
  margin-left: 8px;
}
.city_type{
  margin-left: 8px;
  margin-top: 8px;
}
.lover {
  display: flex;
  margin-top: 8px;
}
.election_year {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px solid black;
  height: 70px;
  padding: 4px;
  margin-left: 4px;
  border-radius: 4px;
}
.year {
  text-align: left;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
.year_info {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.progress_bar {
  width: 200px;
  height: 30px;
  background-color: #d9d9d9;
  overflow: hidden;
  border-radius: 4px;
  margin-top: 8px;
  margin-left: 8px;
}

.progress_bar_inner {
  height: 100%;
  background-color: #00ABCD;
  transition: width 1s ease-in-out;
  text-align: center;
  padding-top: 4px;
  color: black;
  font-size: large;
  font-weight: 600;
}
@media screen and (max-width: 1023px) {
  .card {
    display: none;
  }
}
