.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.card {
  width: 570px;
  height: 85%;
  z-index: 3;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
}
.header {
  width: 100%;
  height: 10%;
  background-color: #00abcd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: aliceblue;
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_p {
  display: inline-block;
  text-align: center;
  margin: 0 auto;
  padding-left: 16px;
  font-family: "Inter", sans-serif;
}
.mayor_picture_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mayor_photo {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid #00abcd;
  margin-top: 8px;
}
.date_container {
  width: 160px;
  height: 7%;
  background-color: #f1f1f1;
  margin: 0 auto;
  margin-top: 8px;

  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.date_header {
  text-align: center;
  font-family: "Inter", sans-serif;
}
.date {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 22px;
}
.explanation_container {
  width: 450px;
  height: 25%;
  background-color: #f1f1f1;
  margin: 0 auto;
  margin-top: 8px;
  border-radius: 4px;

  
}
.explanation_header {
  position: relative;
  right: 5%;
  top: 5%;
  background-color: #00abcd;
  width: fit-content;
  height: fit-content;
  padding: 8px;
  padding-left: 16px;
  padding-right: 16px;
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border-radius: 4px;
}
.update {
  float: right;
  position: relative;
  bottom: 15%;
  margin-right: 16px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #464545;
}
.explanation {
  width: 100%;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  overflow-y: scroll;
  height: 50%;
 
}
.footer_container {
  width: 100%;
  height: 8%;
  background-color: #f1f1f1;
  margin-top: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Inter", sans-serif;
}
.splide {
  width: 500px;
  height: 35%;
  margin: 0 auto;
  margin-top: 8px;
  border-radius: 4px;
}
.slider_active_image {
  width: 500px;
  height: 70%;
  border-radius: 8px;
  margin-top: 4px;
}

.slider_pics {
  width: 100%;
  height: 60px;
  max-height: 150px;
  border-radius: 8px;
  padding-left: 4px;
  padding-right: 4px;
}
.splide_slide {
  width: 500px;
  border-radius: 4px;
}

.splide_container {
  margin: 0 auto;
  margin-top: 8px;
  max-height: 150px;
  width: 500px;
}

.footer_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}
.icon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-left: 8px;
}
.slider_active_image_holder {
  width: 500px;
  height: 70%;
  border-radius: 8px;
  margin-top: 4px;
  background-color: #d9d9d9;
  object-fit: contain;
}
.slider_pics_holder {
  width: 95%;
  height: 60px;
  max-height: 150px;
  border-radius: 8px;
  object-fit: contain;
  background-color: #d9d9d9;
}

@media screen and (max-width: 320px) {
  .card {
    width: 300px;
    height: 500px;
    z-index: 3;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

  }
  .header {
    width: 100%;
    height: 10%;
    background-color: #00abcd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: aliceblue;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_p {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
  }
  .mayor_photo {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    border: 2px solid #00abcd;
    margin-top: 8px;
  }
  .date_container {
    width: 160px;
    height: 7%;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .date_header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  .date {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 700;
  }
  .explanation_container {
    width: 270px;
    height: 25%;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 32px;
    border-radius: 0;
  }
  .explanation_header {
    position: relative;
    left: -10px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    font-size: 12px;
  }
  .update {
    float: right;
    position: relative;
    top: -10px;
    margin-right: 8px;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    color: #464545;
  }
  .explanation {
    padding-top: 16px;
    padding-left: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }

  .footer_container {
    width: 100%;
    height: 12%;
    background-color: #f1f1f1;
    margin-top: 4px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    font-size: small;
    padding-top: 4px;
  }
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 4px;
  }

  .splide {
    width: 270px;
    height: 35%;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 4px;
  }
  .slider_active_image {
    width: 270px;
    height: 70%;
    border-radius: 8px;
    margin-top: 4px;
  }

  .slider_pics {
    width: 100%;
    height: 60px;
    max-height: 150px;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .splide_slide {
    width: 270px;
    border-radius: 4px;
  }

  .splide_container {
    margin: 0 auto;
    margin-top: 8px;
    max-height: 150px;
    width: 270px;
  }

  .footer_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }
  .slider_active_image_holder {
    width: 230px;
    height: 70%;
    border-radius: 8px;
    margin-top: 4px;
    background-color: #d9d9d9;
    object-fit: contain;
  }
  .slider_pics_holder {
    width: 230px;
    height: 60px;
    max-height: 150px;
    border-radius: 8px;
    object-fit: contain;
    background-color: #d9d9d9;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .card {
    width: 350px;
    height: 540px;
    z-index: 3;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

  }
  .header {
    width: 100%;
    height: 10%;
    background-color: #00abcd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: aliceblue;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_p {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
  }
  .mayor_photo {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 2px solid #00abcd;
    margin-top: 8px;
  }
  .date_container {
    width: 160px;
    height: 7%;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .date_header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  .date {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 700;
  }
  .explanation_container {
    width: 290px;
    height: 120px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 32px;
    border-radius: 0;
  }
  .explanation_header {
    position: relative;
    left: -10px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    font-size: 12px;
  }
  .update {
    float: right;
    position: relative;
    top: -10px;
    margin-right: 8px;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    color: #464545;
  }
  .explanation {
    padding-top: 16px;
    padding-left: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }

  .footer_container {
    width: 100%;
    height: 12%;
    background-color: #f1f1f1;
    margin-top: 4px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    font-size: small;
    padding-top: 4px;
  }
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 4px;
  }

  .splide {
    width: 290px;
    height: 35%;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 4px;
  }
  .slider_active_image {
    width: 290px;
    height: 70%;
    border-radius: 8px;
    margin-top: 4px;
  }

  .slider_pics {
    width: 100%;
    height: 60px;
    max-height: 150px;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .splide_slide {
    width: 290px;
    border-radius: 4px;
  }

  .splide_container {
    margin: 0 auto;
    margin-top: 8px;
    max-height: 150px;
    width: 290px;
  }

  .footer_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }
  .slider_active_image_holder {
    width: 250px;
    height: 70%;
    border-radius: 8px;
    margin-top: 4px;
    background-color: #d9d9d9;
    object-fit: contain;
  }
  .slider_pics_holder {
    width: 250px;
    height: 60px;
    max-height: 150px;
    border-radius: 8px;
    object-fit: contain;
    background-color: #d9d9d9;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .card {
    width: 400px;
    height: 540px;
    z-index: 3;
    background-color: rgb(255, 255, 255);
    border-radius: 0px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

  }
  .header {
    width: 100%;
    height: 10%;
    background-color: #00abcd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: aliceblue;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_p {
    display: inline-block;
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    font-size: large;
  }
  .mayor_photo {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    border: 2px solid #00abcd;
    margin-top: 8px;
  }
  .date_container {
    width: 160px;
    height: 7%;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .date_header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
  .date {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 700;
  }
  .explanation_container {
    width: 310px;
    height: 120px;
    background-color: #f1f1f1;
    margin: 0 auto;
    margin-top: 32px;
    border-radius: 0;
  }
  .explanation_header {
    position: relative;
    left: -10px;
    top: 15px;
    background-color: #00abcd;
    width: fit-content;
    height: fit-content;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    color: white;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    border-radius: 4px;
    font-size: 12px;
  }
  .update {
    float: right;
    position: relative;
    top: -10px;
    margin-right: 8px;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    color: #464545;
  }
  .explanation {
    padding-top: 16px;
    padding-left: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }

  .footer_container {
    width: 100%;
    height: 10%;
    background-color: #f1f1f1;
    margin-top: 2px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Inter", sans-serif;
    font-size: small;
    padding-top: 8px;
  }
  .icon {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 4px;
  }

  .splide {
    width: 310px;
    height: 35%;
    margin: 0 auto;
    margin-top: 8px;
    border-radius: 4px;
  }
  .slider_active_image {
    width: 310px;
    height: 70%;
    border-radius: 8px;
    margin-top: 4px;
  }

  .slider_pics {
    width: 100%;
    height: 60px;
    max-height: 150px;
    border-radius: 8px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .splide_slide {
    width: 310px;
    border-radius: 4px;
  }

  .splide_container {
    margin: 0 auto;
    margin-top: 8px;
    max-height: 150px;
    width: 310px;
  }

  .footer_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }
  .slider_active_image_holder {
    width: 270px;
    height: 70%;
    border-radius: 8px;
    margin-top: 4px;
    background-color: #d9d9d9;
    object-fit: contain;
  }
  .slider_pics_holder {
    width: 270px;
    height: 60px;
    max-height: 150px;
    border-radius: 8px;
    object-fit: contain;
    background-color: #d9d9d9;
  }
}
