.container {
  height: max-content;
  padding-top: 82px;
  padding-bottom: 32px;
}
.header {
  width: 50%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00abcd;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #ffffff;
  text-transform: capitalize;
  margin: 0 auto;
  border-radius: 4px;
}
.list {
  margin: 0 auto;
  width: 50%;
}
.vaat {
  margin-left: 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.element {
  border: 2px solid black;
  border-radius: 8px;
  padding: 16px;
  margin-top: 12px;
  background-color: #ffffff;
}
.promises {
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.index {
  border: 2px solid black;
  border-radius: 40px;
  padding: 8px;
  width: 40px;
  height: 40px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.icon {
  width: 15px;
  height: 15px;
}
.announcement {
  width: 200px;
  margin-left: 8px;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.button_container {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.download_button {
  background-color: #00abcd;
  color: #ffffff;
  padding: 16px;
  border: none;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.pagination button {
  margin: 0 5px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #f2f2f2;
}

.pagination button.active {
  background-color: #00abcd;
  color: #fff;
}
.announcement_image {
  margin-left: 8px;
  width: 21px;
  height: 21px;
}
.announcement_container {
  display: flex;
  justify-content: flex-start;
  background-color: #e6e6e6;
  align-items: center;
  margin-left: 60px;
  width: 216px;
  height: 42px;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-size: medium;
  font-weight: 500;
}

@media screen and (max-width: 320px) {
  .container {
    height: max-content;
    padding-top: 82px;
    padding-bottom: 32px;
  }
  .header {
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00abcd;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #ffffff;
    text-transform: capitalize;
    margin: 0 auto;
    border-radius: 4px;
  }
  .list {
    margin: 0 auto;
    width: 300px;
  }
  .vaat {
    margin-left: 24px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: small;
    font-weight: 500;
  }
  .element {
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px;
    margin-top: 12px;
    background-color: #ffffff;
  }
  .promises {
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .index {
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 8px;
    width: 25px;
    height: 25px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: medium;
    font-weight: 500;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
  .announcement_image {
    margin-left: 8px;
    width: 12px;
    height: 12px;
  }
  .situation {
    width: 25px;
    height: 25px;
  }
  .announcement_container {
    display: flex;
    justify-content: flex-start;
    background-color: #e6e6e6;
    align-items: center;
    margin-left: 40px;
    width: 150px;
    height: 22px;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-weight: 500;
  }
}


@media only screen and (min-width: 375px) and (max-width: 425px) {
  .container {
    height: max-content;
    padding-top: 82px;
    padding-bottom: 32px;
  }
  .header {
    width: 350px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00abcd;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #ffffff;
    text-transform: capitalize;
    margin: 0 auto;
    border-radius: 4px;
  }
  .list {
    margin: 0 auto;
    width: 350px;
  }
  .vaat {
    margin-left: 24px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: small;
    font-weight: 500;
  }
  .element {
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px;
    margin-top: 12px;
    background-color: #ffffff;
  }
  .promises {
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .index {
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 8px;
    width: 30px;
    height: 30px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: medium;
    font-weight: 500;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
  .announcement_image {
    margin-left: 8px;
    width: 14px;
    height: 14px;
  }
  .situation {
    width: 30px;
    height: 30px;
  }
  .announcement_container {
    display: flex;
    justify-content: flex-start;
    background-color: #e6e6e6;
    align-items: center;
    margin-left: 50px;
    width: 150px;
    height: 26px;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    font-weight: 500;
  }
}


@media only screen and (min-width: 425px) and (max-width: 768px) {
  .container {
    height: max-content;
    padding-top: 82px;
    padding-bottom: 32px;
  }
  .header {
    width: 400px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00abcd;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #ffffff;
    text-transform: capitalize;
    margin: 0 auto;
    border-radius: 4px;
  }
  .list {
    margin: 0 auto;
    width: 400px;
  }
  .vaat {
    margin-left: 24px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: small;
    font-weight: 500;
  }
  .element {
    border: 2px solid black;
    border-radius: 8px;
    padding: 8px;
    margin-top: 12px;
    background-color: #ffffff;
  }
  .promises {
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .index {
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 8px;
    width: 40px;
    height: 40px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-size: medium;
    font-weight: 500;
  }
  .icon {
    width: 15px;
    height: 15px;
  }
  .announcement_image {
    margin-left: 8px;
    width: 16px;
    height: 16px;
  }
  .situation {
    width: 40px;
    height: 40px;
  }
  .announcement_container {
    display: flex;
    justify-content: flex-start;
    background-color: #e6e6e6;
    align-items: center;
    margin-left: 55px;
    width: 170px;
    height: 32px;
    border-radius: 8px;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
  }
}
