@media screen and (max-width: 374px) {
  .card {
    width: 250px;
    height: 320px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  }

  .mayor_photo {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    border: 2px solid #00abcd;
    float: right;
    position: relative;
    top: -35px;
    left: 35px;
  }
  .province_area {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
    position: relative;
    left: -20px;
    top: -35px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f5f5f5;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    padding: 8px;
    margin-left: 7.5px;
  }
  .icon {
    width: 14px;
    height: 19px;
  }
  .province_name_city {
    margin-left: 4px;
    color: aliceblue;
  }

  .party_logo {
    width: 35px;
    height: 35px;
    padding: 4px;
    border: 2px solid black;
    background-size: contain;
    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .card_content {
    margin-left: 8px;
    position: relative;
    top: -35px;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
    
  }
  .mayor_name {
    font-size: 18px;
  }
  .mayor_party {
    font-size: 15px;
    font-weight: 400;
  }
  .lover {
    display: flex;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
  }
  .election_year {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    height: 35px;
    padding: 4px;
    margin-left: 4px;
    border-radius: 4px;
  }
  .year {
    font-size: 14px;
    font-weight: 700;
  }
  .year_info {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-family: 800;
    font-style: bold;
    color: #f5f5f5;
    text-align: left;
    margin-left: 4px;
  }
  .bio {
    margin-top: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    overflow-y: scroll;
    padding-left: 8px;
    padding-right: 8px;
    height: 120px;
    text-align: justify;
    position: relative;
    top: -35px;

    color: #2b2b2b;
  }
  ::-webkit-scrollbar {
    background-color: rgb(245, 245, 245);
  }

  .province_name {
    margin-left: 4px;
    color: aliceblue;
    text-transform: uppercase;
    font-size: 13px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .card {
    width: 305px;
    height: 320px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  }

  .mayor_photo {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    border: 2px solid #00abcd;
    float: right;
    position: relative;
    top: -35px;
    left: 35px;
  }
  .province_area {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 500;
    width: 200px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
    position: relative;
    left: -20px;
    top: 35px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f5f5f5;
    width: 30px;
    height: 30px;
    border-radius: 40px;
    padding: 8px;
    margin-left: 7.5px;
  }
  .icon {
    width: 14px;
    height: 19px;
  }
  .province_name_city {
    margin-left: 4px;
    color: aliceblue;
  }

  .party_logo {
    width: 48px;
    height: 48px;
    padding: 4px;
    border: 2px solid black;
    background-size: contain;
    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .card_content {
    margin-left: 8px;
    position: relative;
    top: 35px;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
  }
  .mayor_name {
    font-size: 19px;
  }
  .mayor_party {
    font-size: 16px;
    font-weight: 400;
  }
  .lover {
    display: flex;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
  }
  .election_year {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    height: max-content;
    padding: 4px;
    margin-left: 4px;
    border-radius: 4px;
  }
  .year {
    font-size: 15px;
    font-weight: 700;
  }
  .year_info {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-family: 800;
    font-style: bold;
    color: #f5f5f5;
    text-align: left;
    margin-left: 4px;
  }
  .bio {
    margin-top: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    overflow-y: scroll;
    padding-left: 8px;
    padding-right: 8px;
    height: 120px;
    text-align: justify;
    position: relative;
    top: 35px;

    color: #2b2b2b;
  }
  ::-webkit-scrollbar {
    background-color: rgb(245, 245, 245);
  }

  .province_name {
    margin-left: 4px;
    color: aliceblue;
    text-transform: uppercase;
    font-size: 13px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .card {
    width: 355px;
    height: 320px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  }

  .mayor_photo {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    border: 2px solid #00abcd;
    float: right;
    position: relative;
    top: -35px;
    left: 35px;
  }
  .province_area {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-weight: 500;
    width: 200px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
    position: relative;
    left: -20px;
    top: 35px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #f5f5f5;
    width: 35px;
    height: 35px;
    border-radius: 40px;
    padding: 8px;
    margin-left: 5px;
  }
  .icon {
    width: 16px;
    height: 21px;
  }
  .province_name_city {
    margin-left: 4px;
    color: aliceblue;
  }

  .party_logo {
    width: 48px;
    height: 48px;
    padding: 4px;
    border: 2px solid black;
    background-size: contain;
    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .card_content {
    margin-left: 8px;
    position: relative;
    top: 35px;
    margin-top: 8px;
  }
  .mayor_name {
    font-size: 19px;
  }
  .mayor_party {
    font-size: 16px;
    font-weight: 400;
  }
  .lover {
    display: flex;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
  }
  .election_year {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    height: max-content;
    padding: 4px;
    margin-left: 4px;
    border-radius: 4px;
  }
  .year {
    font-size: 15px;
    font-weight: 700;
  }
  .year_info {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-family: 800;
    font-style: bold;
    color: #f5f5f5;
    text-align: left;
    margin-left: 4px;
  }
  .bio {
    margin-top: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    overflow-y: scroll;
    padding-left: 8px;
    padding-right: 8px;
    height: 120px;
    text-align: justify;
    position: relative;
    top: 35px;

    color: #2b2b2b;
  }
  ::-webkit-scrollbar {
    background-color: rgb(245, 245, 245);
  }

  .province_name {
    margin-left: 4px;
    color: aliceblue;
    text-transform: uppercase;
    font-size: 15px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .card {
    width: 700px;
    height: 320px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  }

  .mayor_photo {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    border: 2px solid #00abcd;
    float: right;
    position: relative;
    top: -35px;
    left: 35px;
  }
  .province_area {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 19px;
    font-weight: 500;
    width: 210px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
    position: relative;
    left: -25px;
    top: 35px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 8px;
  }
  .icon {
    width: 18px;
    height: 23px;
  }
  .province_name_city {
    margin-left: 8px;
    color: aliceblue;
  }

  .party_logo {
    width: 48px;
    height: 48px;
    padding: 4px;
    border: 2px solid black;
    background-size: contain;
    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .card_content {
    margin-left: 8px;
    position: relative;
    top: 35px;
    margin-top: 8px;
  }
  .mayor_name {
    font-size: 20px;
  }
  .mayor_party {
    font-size: 17px;
    font-weight: 400;
  }
  .lover {
    display: flex;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
  }
  .election_year {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    height: max-content;
    padding: 4px;
    margin-left: 4px;
    border-radius: 4px;
  }
  .year {
    font-size: 15px;
    font-weight: 700;
  }
  .year_info {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-family: 800;
    font-style: bold;
    color: #f5f5f5;
    text-align: left;
    margin-left: 4px;
  }
  .bio {
    margin-top: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    overflow-y: scroll;
    padding-left: 8px;
    padding-right: 8px;
    height: 120px;
    text-align: justify;
    position: relative;
    top: 35px;

    color: #2b2b2b;
  }
  ::-webkit-scrollbar {
    background-color: rgb(245, 245, 245);
  }

  .province_name {
    margin-left: 4px;
    color: aliceblue;
    text-transform: uppercase;
    font-size: 16px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .card {
    width: 425px;
    height: 320px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  }

  .mayor_photo {
    width: 90px;
    height: 90px;
    border-radius: 90px;
    border: 2px solid #00abcd;
    float: right;
    position: relative;
    top: -45px;
    left: 45px;
  }
  .province_area {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 21px;
    font-weight: 500;
    width: 230px;
    height: 70px;
    background-color: #00abcd;
    border-radius: 8px;
    position: relative;
    left: -25px;
    top: 40px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 8px;
  }
  .icon {
    width: 18px;
    height: 23px;
  }
  .province_name_city {
    margin-left: 8px;
    color: aliceblue;
  }

  .party_logo {
    width: 48px;
    height: 48px;
    padding: 4px;
    border: 2px solid black;
    background-size: contain;
    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .card_content {
    margin-left: 8px;
    position: relative;
    top: 35px;
    margin-top: 8px;
  }
  .mayor_name {
    font-size: 22px;
  }
  .mayor_party {
    font-size: 19px;
    font-weight: 400;
  }
  .lover {
    display: flex;
    margin-top: 8px;
    font-family: "Inter", sans-serif;
  }
  .election_year {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    height: max-content;
    padding: 4px;
    margin-left: 4px;
    border-radius: 4px;
  }
  .year {
    font-size: 15px;
    font-weight: 500;
  }
  .year_info {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-family: 800;
    font-style: bold;
    color: #f5f5f5;
    text-align: left;
    margin-left: 8px;
  }
  .bio {
    margin-top: 8px;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    overflow-y: scroll;
    padding-left: 8px;
    padding-right: 8px;
    height: 90px;
    text-align: justify;
    position: relative;
    top: 35px;

    color: #2b2b2b;
  }
  ::-webkit-scrollbar {
    background-color: rgb(245, 245, 245);
  }

  .province_name {
    margin-left: 8px;
    color: aliceblue;
    text-transform: uppercase;
    font-size: 15px;
  }
}

@media screen and (min-width: 1440px)  {
  .card {
    width: 425px;
    height: 400px;
    background-color: white;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.6);
  }

  .mayor_photo {
    width: 110px;
    height: 110px;
    border-radius:110px;
    border: 2px solid #00abcd;
    float: right;
    position: relative;
    top: -55px;
    left: 55px;
  }
  .province_area {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 21px;
    font-weight: 500;
    width: 230px;
    height: 70px;
    background-color: #00abcd;
    border-radius: 8px;
    position: relative;
    left: -30px;
    top: 40px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    width: 45px;
    height: 45px;
    border-radius: 45px;
    margin-left: 8px;
  }
  .icon {
    width: 21px;
    height: 26px;
  }
  .province_name_city {
    margin-left: 8px;
    color: aliceblue;
  }

  .party_logo {
    width: 54px;
    height: 54px;
    padding: 4px;
    border: 2px solid black;
    background-size: contain;
    border-radius: 4px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .card_content {
    margin-left: 16px;
    position: relative;
    top: 35px;
    margin-top: 16px;
    font-family: "Inter", sans-serif;
  }
  .mayor_name {
    font-size: 25px;
    
  }
  .mayor_party {
    font-size: 20px;
    margin-top: 4px;
    font-weight: 400;
  }
  .lover {
    display: flex;
    margin-top: 16px;
    font-family: "Inter", sans-serif;
  }
  .election_year {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid black;
    height: max-content;
    padding: 4px;
    margin-left: 8px;
    border-radius: 4px;
  }
  .year {
    font-size: 26px;
    font-weight: 700;
  }
  .year_info {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    font-family: 800;
    font-style: bold;
    color: #f5f5f5;
    text-align: left;
    margin-left: 8px;
  }
  .bio {
    margin-top: 16px;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    overflow-y: scroll;
    padding-left: 16px;
    padding-right: 16px;
    height: 120px;
    text-align: justify;
    position: relative;
    top: 35px;

    color: #2b2b2b;
  }
  ::-webkit-scrollbar {
    background-color: rgb(245, 245, 245);
  }

  .province_name {
    margin-left: 8px;
    color: aliceblue;
    text-transform: uppercase;
    font-size: 15px;
  }
}

