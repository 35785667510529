.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.card {
  width: 500px;
  height: 570px;
  z-index: 3;
  border-radius: 8px;
}
.popup_image {
  width: 100%;
  height: 100%;  
  border-radius: 8px;
  position: relative;
  top: -45px;
  
}
.icon {
  position: relative;
  float: right;
  margin: 10px 10px;
  padding: 4px;
  background-color: transparent;
  color: black;
  background-color: transparent;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  z-index: 2;
}
@media screen and (max-width: 320px) {
  .card {
    width: 300px;
    height: 180px;
    z-index: 3;
    background-color: transparent;
    border-radius: 8px;
  }
}
@media screen and (max-width: 320px) {
  .card {
    width: 300px;
    height: 180px;
    z-index: 3;
    background-color: transparent;
    border-radius: 8px;
  }
}
@media screen and (min-width: 375px) and (max-width: 424px) {
  .card {
    width: 350px;
    height: 220px;
    z-index: 3;
    background-color: transparent;
    border-radius: 8px;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .card {
    width: 400px;
    height: 270px;
    z-index: 3;
    background-color: transparent;
    border-radius: 8px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .card {
    width: 700px;
    height: 520px;
    z-index: 3;
    background-color: transparent;
    border-radius: 8px;
  }
}
