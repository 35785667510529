.cookies_container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: max-content;
  background-color: #f5f5f5;
  display: flex;
 
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
}
.info {
  width: 75%;
  text-align: left;
  padding: 16px;
  font-family: "Inter", sans-serif;
  font-size: medium;
  height: max-content;
  

}
.buttons {
  width: max-content;
  font-family: "Inter", sans-serif;
  display: flex;
  
}
.text {
  font-family: "Inter", sans-serif;
}
.accept {
  margin-right: 16px;
  background-color:#00abcd;
  border-radius: 4px;
  padding: 12px;
  border: none;
  font-weight: 600;
  color: white;
  
}
.reject {
  margin-right: 16px;
  background-color:#E9E9E9;
  border-radius: 4px;
  padding: 12px;
  border: none;
  font-weight: 600;
  color: black;
}
@media screen and (max-width:1023px) {
  .info{
    font-size: small;
  }
}