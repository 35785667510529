@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");

.navbar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  text-align: center;
  background-color: transparent;
  padding: 8px 0 8px 0;
  position: fixed;
  top: 0;
  z-index: 2;
}
.logo {
  width: 117px;
  height: 62px;
}
.scrolled {
  background-color: whitesmoke;
  transition: 0.3s ease-in-out;
}

.links {
  display: flex;
  list-style: none;
}
.link {
  margin: 0 8px;
}
.link > a {
  text-decoration: none;
  padding: 8px;
  color: black;
  font-family: "Poppins", sans-serif;
}
.link > a:hover {
  background-color: #f5f5f5;
  border-radius: 16px;
  border: 0.5px solid;
}
.search_area {
  display: flex;
  justify-content: center;
  align-items: center;
}
.search_bar {
  width: 270px;
  height: 40px;
  padding-left: 16px;
  border-radius: 8px;
  border: 1px solid #d9dcde;
  outline: none;
  background-color: #f5f7fa;
}
.search_bar::-webkit-search-cancel-button {
  position: relative;
  left: -40px;
  align-items: center;
}
.search_bar::placeholder {
  color: black;
}
.search_icon {
  position: relative;
  width: 20px;
  height: 20px;
  left: -35px;
  border-radius: 16px;
}
.menu {
  display: none;
}
.search_results_container {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
}
.search_results {
  position: absolute;
  top: 5px;
  right: 100px;
  width: 35%;
  max-height: 250px;
  overflow-y: auto;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 9999; /* Ensure the popup appears on top of other elements */

  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.search_results ul {
  list-style: none;
}
.search_results a {
  text-decoration: none;
  color: black;
}

.search_results li {
  cursor: pointer;
  text-align: left;
  padding-bottom: 8px;
  padding-top: 8px;
  border-bottom: 2px solid #e5e5e5;
}

.search_results li:hover {
  background-color: #e5e5e5;
}

.result_container {
  display: flex;
  flex-direction: column;
}
.result {
  color: #000000;
  font-family: "Inter", sans-serif;
  margin-left: 16px;
  font-weight: 600;
  padding: 8px;
}
.type1 {
  color: #0085ff;
  background-color: #e2f1ff;
  width: max-content;
  padding: 8px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  border-radius: 8px;
  margin-left: 16px;
}

.type2 {
  color: #fd0000;
  background-color: #ffe2e2;
  width: max-content;
  padding: 8px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  border-radius: 8px;
  margin-left: 16px;
}

.type3 {
  color: #f5ad6a;
  background-color: rgb(240, 129, 25, 0.1);
  width: max-content;
  padding: 8px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  border-radius: 8px;
  margin-left: 16px;
}

.type4 {
  color: #b8aefa;
  background-color: rgba(127, 107, 255, 0.815);
  width: max-content;
  width: max-content;
  padding: 8px;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  border-radius: 8px;
  margin-left: 16px;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: block;
    padding: 0;
    background-color: #f5f5f5;
    width: 100%;
  }
  .icon {
    padding-top: 16px;
    margin-bottom: 16px;
  }
  .logo {
    width: 100px;
    height: 30px;
  }
  .link_area {
    display: none;
  }
  .search_area {
    display: none;
  }

  .links {
    justify-content: center;
    flex-direction: column;
  }
  .link {
    margin-bottom: 8px;
  }
  .link > a {
    border-bottom: 1px solid;
    padding-bottom: 0;
  }
  .search_bar {
    width: 75%;
    margin-top: 8px;
    margin-bottom: 16px;
    background-color: white;
  }
  .menu {
    display: block;
    position: absolute;
    top: 30px;
    right: 30px;
  }
  .bar {
    display: block;
    position: relative;
    top: -10px;
  }
  .cross {
    display: none;
  }
  .responsive_nav {
    display: block;
  }
  .search_results {
    position: absolute;
    top: 0px;
    right: 7.5%;
    width: 85%;
    max-height: 250px;
    overflow-y: auto;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 9999;

    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
}
