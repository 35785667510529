
@media (max-width: 1023px) {
  .container {
    position: relative;
    top: 80px;
    width: 100%;
    margin-bottom: 80px;
  }
  .arrow {
    display: none;
   
  }
  
}
@media only screen and (min-width: 1440px)  {
  .container {
    position: relative;
    top: 80px;
    width: 100%;
    margin-bottom: 80px;
  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -80px;
  }
  .icon{
   
    height: 50px;
    
  }
  
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
}
@media only screen and (min-width: 1024px) and (max-width:1440px) {
  .container {
    position: relative;
    top: 80px;
    width: 100%;
    margin-bottom: 60px;
  }
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -60px;
  }
  .icon{
   
    height: 40px;
  }
}

