.hr {
  width: 64px;
  height: 5px;
  margin: 0 auto;
  background-color: #00abcd;
  border-radius: 8px;
}
@media screen and (max-width: 374px) {
  .container {
    height: max-content;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: x-large;
  }
  .grid_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  }

  .card {
    width: 300px;
    height: 320px;
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    color: black;
    text-decoration: none;
    justify-self: center;
    border-radius: 8px;
  }
  .blurEffect {
    position: relative;
  }

  .blurEffect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    backdrop-filter: blur(5px);
    z-index: 1;
  }
  .time_area {
    margin-left: 49px;
    margin-top: 8px;
    font-weight: 300;
  }

  .upper_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 14px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 13px;
    height: 13px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .blog_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }

  .blog {
    position: relative;
    height: 50%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 20px;
    max-lines: 4;
  }
  .blog1 {
    position: relative;
    height: 120px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 16px;
    text-align: left;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    font-size: 15px;
    line-height: 20px;
    max-lines: 4;
  }

  .blog::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .button {
    width: 130px;
    height: 40px;
    background-color: rgb(175, 175, 175, 0.1);
    margin-right: 16px;
    margin-top: 8px;
    float: right;
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
  }
  .button_all {
    position: absolute;
    top: 50%;
    left: 25%;
    z-index: 1;
    width: 140px;
    height: 44px;
    background-color: rgb(0, 0, 0);
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: white;
  }
  .arrow {
    opacity: 0.5;
    transition: opacity 1.5s ease;
  }

  .link {
    text-decoration: none;
    color: white;
  }
}
@media only screen and (min-width: 375px) and (max-width: 424px) {
  .container {
    height: max-content;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: x-large;
  }
  .grid_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  }

  .card {
    width: 355px;
    height: 340px;
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    color: black;
    text-decoration: none;
    justify-self: center;
    border-radius: 8px;
  }
  .blurEffect {
    position: relative;
  }

  .blurEffect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ); /* Adjust the opacity (last value) as needed */
    backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
    z-index: 1;
  }
  .time_area {
    margin-left: 49px;
    margin-top: 8px;
    font-weight: 300;
  }

  .upper_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 15px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 14px;
    height: 14px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .blog_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 18px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }

  .blog {
    position: relative;
    height: 50%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 15px;
    line-height: 20px;
    max-lines: 4;
  }
  .blog1 {
    position: relative;
    height: 150px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 20px;
    max-lines: 4;
  }

  .blog::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .button {
    width: 130px;
    height: 40px;
    background-color: rgb(175, 175, 175, 0.1);
    margin-right: 16px;
    margin-top: 8px;
    float: right;
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
  }
  .button_all {
    position: absolute;
    top: 50%;
    left: 30%;
    z-index: 1;
    width: 140px;
    height: 44px;
    background-color: rgb(0, 0, 0);
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: white;
  }
  .arrow {
    opacity: 0.5;
    transition: opacity 1.5s ease;
  }

  .link {
    text-decoration: none;
    color: white;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .container {
    height: max-content;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: xx-large;
  }
  .grid_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  }

  .card {
    width: 405px;
    height: 350px;
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    color: black;
    text-decoration: none;
    justify-self: center;
    border-radius: 8px;
  }
  .blurEffect {
    position: relative;
  }

  .blurEffect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ); /* Adjust the opacity (last value) as needed */
    backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
    z-index: 1;
  }
  .time_area {
    margin-left: 52px;
    margin-top: 8px;
    font-weight: 300;
  }

  .upper_part {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 15%;
    font-size: 16px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 14px;
    height: 14px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .author_picture {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .blog_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 20px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }

  .blog {
    position: relative;
    height: 50%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 20px;
    max-lines: 4;
  }
  .blog1 {
    position: relative;
    height: 150px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 20px;
    max-lines: 4;
  }

  .blog::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .button {
    width: 130px;
    height: 40px;
    background-color: rgb(175, 175, 175, 0.1);
    margin-right: 16px;
    margin-top: 8px;
    float: right;
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
  }
  .button_all {
    position: absolute;
    top: 50%;
    left: 35%;
    z-index: 1;
    width: 140px;
    height: 44px;
    background-color: rgb(0, 0, 0);
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: white;
  }
  .arrow {
    opacity: 0.5;
    transition: opacity 1.5s ease;
  }

  .link {
    text-decoration: none;
    color: white;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    height: max-content;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: xx-large;
  }
  .grid_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    margin-top: 16px;
  }

  .card {
    width: 405px;
    height: 360px;
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    color: black;
    text-decoration: none;
    justify-self: center;
    border-radius: 8px;
  }
  .blurEffect {
    position: relative;
  }

  .blurEffect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ); /* Adjust the opacity (last value) as needed */
    backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
    z-index: 1;
  }
  .time_area {
    margin-left: 52px;
    font-weight: 300;
  }

  .upper_part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 15%;
    font-size: 12px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 12px;
    height: 12px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author {
    display: flex;
    align-items: center;
    width: 50%;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .author_picture {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .blog_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 15%;
    font-size: 20px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }

  .blog {
    position: relative;
    height: 50%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 20px;
    max-lines: 4;
  }
  .blog1 {
    position: relative;
    height: 150px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 20px;
    max-lines: 4;
  }

  .blog::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .button {
    width: 130px;
    height: 40px;
    background-color: rgb(175, 175, 175, 0.1);
    margin-right: 16px;
    margin-top: 8px;
    float: right;
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
  }
  .button_all {
    position: absolute;
    top: 50%;
    left: 35%;
    z-index: 1;
    width: 140px;
    height: 44px;
    background-color: rgb(0, 0, 0);
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: white;
  }
  .arrow {
    opacity: 0.5;
    transition: opacity 1.5s ease;
  }

  .link {
    text-decoration: none;
    color: white;
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    height: 100vh;
    padding-top: 82px;
    width: 100%;
  }
  .header {
    text-align: center;
    margin-bottom: 16px;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: xx-large;
  }
  .grid_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-top: 16px;
  }
  .card.cardRight {
    justify-self: end;
  }

  .card {
    width: 500px;
    height: 320px;
    background-color: #ffffff;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    color: black;
    text-decoration: none;
  }
  .blurEffect {
    position: relative;
  }

  .blurEffect::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    ); /* Adjust the opacity (last value) as needed */
    backdrop-filter: blur(5px); /* Adjust the blur amount as needed */
    z-index: 1;
  }

  .time_area {
    margin-left: 52px;

    font-weight: 300;
  }

  .upper_part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 15%;
    font-size: 14px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 12px;
    height: 12px;
    padding-right: 4px;
    padding-left: 4px;
  }
  .author {
    display: flex;
    align-items: center;
    width: 50%;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .blog_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 20%;
    font-size: 22px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
  }

  .blog {
    position: relative;
    height: 40%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 20px;
    max-lines: 4;
  }
  .blog1 {
    position: relative;
    height: 120px;
    margin-left: 32px;
    margin-right: 32px;
    margin-top: 16px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 8px;
    text-overflow: ellipsis;
    font-size: 15px;
    line-height: 20px;
    max-lines: 4;
  }

  .blog::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    backdrop-filter: blur(1px);
    z-index: 1;
  }

  .button {
    width: 150px;
    height: 44px;
    background-color: rgb(175, 175, 175, 0.1);
    margin-right: 32px;
    margin-top: 8px;
    margin-bottom: 8px;
    float: right;
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
  }
  .button_all {
    position: absolute;
    top: 50%;
    left: 35%;
    z-index: 1;
    width: 150px;
    height: 44px;
    background-color: rgb(0, 0, 0);
    border: 1px solid black;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    color: white;
  }
  .arrow {
    opacity: 0.5;
    transition: opacity 1.5s ease;
  }
  .centerLastCard {
    grid-column: span 2; /* Make the card span across both columns */
    justify-self: center; /* Horizontally center the card within the grid */
  }
  .link {
    text-decoration: none;
    color: white;
  }
}
