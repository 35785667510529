@media screen and (min-width: 1440px) {
  .container {
    display: flex;
    justify-content: center;

    flex-direction: column;
    margin-bottom: 32px;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: xx-large;
    margin-bottom: 16px;
    text-align: left;
    width: 770px;
  }
  .card_container {
    display: flex;

    align-items: center;
    flex-direction: column;

    padding: 16px;
    gap: 16px;
    overflow-y: auto;
    height: 1250px;
  }
  .card {
    width: 770px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .body {
    width: 60%;
    height: 100%;
  }
  .article_picture {
    width: 40%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  .article_title {
    width: 100%;
    height: 25%;
    text-align: left;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    font-size: larger;
    overflow: hidden;
    margin-top: 8px;
    padding-top: 8px;
  }
  .article {
    width: 100%;
    height: 40%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    overflow: hidden;
    line-height: 20px;
    color: #535659;
  }

  .time {
    margin-left: 28px;
    margin-top: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .lover_part {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: "Inter", sans-serif;
  }
  .read_time {
    margin-left: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .icon {
    margin-left: 4px;
    width: 15px;
    height: 15px;
    color: #afafaf;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .author {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .author_name {
    margin-left: 4px;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@media only screen and (max-width: 374px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 16px;
    width: 100%;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: x-large;
    margin-top: 16px;
    text-align: center;
  }
  .card_container {
    display: flex;

    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    gap: 16px;
    overflow-y: auto;
    height: max-content;
  }
  .card {
    width: 300px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .body {
    width: 70%;
    height: 100%;
  }
  .article_picture {
    width: 30%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .article_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: "Inter", sans-serif;
    width: 100%;
    height: 25%;
    font-size: 14px;
    font-weight: bold;
    padding-top: 8px;
    text-align: left;
    text-transform: capitalize;
    padding-left: 16px;
    padding-right: 16px;
  }
  .article {
     position: relative;
    height: 50%;
    padding-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 20px;
    max-lines: 4;
    color: #535659;
  }

  .time {
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   
    font-size: 12px;
    margin-top: 8px;
    
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    width: 12px;
    height: 12px;
    padding-right: 4px;
    padding-left: 4px;
    color: #afafaf;
  }
  .author_picture {
    width: 20px;
    height: 20px;
    border-radius: 30px;
  }
  .author {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    padding-top: 4px;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: x-large;
    margin-top: 16px;
    text-align: center;
    margin-left: 16px;
  }
  .card_container {
    display: flex;

    align-items: center;
    flex-direction: column;
    margin-top: 16px;

    gap: 16px;
    overflow-y: auto;
    height: max-content;
  }
  .card {
    width: 350px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .body {
    width: 70%;
    height: 100%;
  }
  .article_picture {
    width: 30%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .article_title {
    width: 100%;
    height: 25%;
    text-align: left;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    font-size: medium;
    overflow: hidden;
    margin-top: 8px;
    padding-top: 8px;
  }
  .article {
    width: 100%;
    height: 47%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    overflow: hidden;
    line-height: 20px;
    color: #535659;
  }

  .time {
    margin-left: 28px;
    margin-top: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .lover_part {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: "Inter", sans-serif;
    margin-top: 8px;
  }
  .read_time {
    margin-left: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .icon {
    margin-left: 4px;
    width: 15px;
    height: 15px;
    color: #afafaf;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
  .author {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .author_name {
    margin-left: 4px;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: x-large;
    margin-top: 16px;
    text-align: center;
    margin-left: 16px;
  }
  .card_container {
    display: flex;

    align-items: center;
    flex-direction: column;
    margin-top: 16px;

    gap: 16px;
    overflow-y: auto;
    height: max-content;
  }
  .card {
    width: 400px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .body {
    width: 70%;
    height: 100%;
  }
  .article_picture {
    width: 30%;
    height: 100%;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .article_title {
    width: 100%;
    height: 25%;
    text-align: left;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    font-size: medium;
    overflow: hidden;
    margin-top: 8px;
    padding-top: 8px;
  }
  .article {
    width: 100%;
    height: 47%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    overflow: hidden;
    line-height: 20px;
    color: #535659;
  }

  .time {
    margin-left: 28px;
    margin-top: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .lover_part {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: "Inter", sans-serif;
    margin-top: 8px;
  }
  .read_time {
    margin-left: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .icon {
    margin-left: 4px;
    width: 15px;
    height: 15px;
    color: #afafaf;
  }
  .author_picture {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
  .author {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .author_name {
    margin-left: 4px;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 16px;
    text-align: left;
    margin-left: 16px;
    width: 700px;
    
   
  }
  
  .card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    margin-right: 8px;
    gap: 16px;
    overflow-y: auto;
    height: max-content;
  }
  .card {
    width: 700px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .body {
    width: 60%;
    height: 100%;
  }
  .article_picture {
    width: 40%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .article_title {
    width: 100%;
    height: 25%;
    text-align: left;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    font-size: larger;
    overflow: hidden;
    margin-top: 8px;
    padding-top: 8px;
  }
  .article {
    width: 100%;
    height: 47%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    overflow: hidden;
    line-height: 20px;
    color: #535659;
  }

  .time {
    margin-left: 52px;
    margin-top: 8px;
    font-weight: 300;
  }
  .lover_part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 15%;
    font-size: 12px;

    margin-top: 8px;
  }
  .read_time {
    padding-left: 4px;
    font-weight: 300;
  }
  .icon {
    margin-left: 4px;
    width: 14px;
    height: 14px;
    color: #afafaf;
  }
  .author_picture {
    width: 28px;
    height: 28px;
    border-radius: 30px;
    margin-left: 16px;
  }
  .author {
    display: flex;
    align-items: center;
  }
  .author_name {
    padding-left: 8px;
    font-weight: 500;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .container {
    display: flex;

    align-items: center;
    flex-direction: column;
    margin-bottom: 32px;
  }
  .header {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: xx-large;
    margin-top: 16px;
    text-align: left;
    width: 970px;
  }
  .card_container {
    display: flex;

    align-items: center;
    flex-direction: column;
    margin-top: 16px;
    padding: 16px;

    gap: 16px;
    overflow-y: auto;
    height: max-content;
    max-height: 1650px;
  }
  .card {
    width: 970px;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    color: black;
    text-decoration: none;
  }
  .body {
    width: 60%;
    height: 100%;
  }
  .article_picture {
    width: 40%;
    height: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .article_title {
    width: 100%;
    height: 25%;
    text-align: left;
    padding-left: 16px;
    font-family: "Inter", sans-serif;
    text-transform: capitalize;
    font-weight: 700;
    font-size: larger;
    overflow: hidden;
    margin-top: 8px;
    padding-top: 8px;
  }
  .article {
    width: 100%;
    height: 47%;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
    text-align: justify;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    overflow: hidden;
    line-height: 20px;
    color: #535659;
  }

  .time {
    margin-left: 28px;
    margin-top: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .lover_part {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: "Inter", sans-serif;
  }
  .read_time {
    margin-left: 4px;
    font-weight: 300;
    font-size: small;
    color: #afafaf;
  }
  .icon {
    margin-left: 4px;
    width: 15px;
    height: 15px;
    color: #afafaf;
  }
  .author_picture {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .author {
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .author_name {
    margin-left: 4px;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}
