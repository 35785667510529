.container{
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 32px;
    
}
@media only screen and (max-width: 1439px) {
    .container{
        display: flex;
        justify-content: center;
       
        flex-direction: column;
        margin-top: 32px;
        
    }
}