.ad {
  
  width: 160px;
  height: 600px;
  margin-left: 32px;
  margin-right: 32px;
}
@media (max-width: 768px) {
  .ad {
    background-color: green;
    width: 300px;
    height: 250px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
