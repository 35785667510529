@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
.hr{
  width: 64px;
  height: 5px;
  margin: 0 auto;
  background-color: #00abcd;
  border-radius: 8px;
  margin-top: 8px;
}
@media (min-width: 1024px) {
  .content_container {
    width: 100%;
    height: 100vh;
    
    padding-top: 82px;
  }
  .container {
    margin: 0 auto;
    height: 80%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .splide {
    height: 85vh;
    align-items: center;
  }
  .header {
    text-align: center;
   
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
  }
  .map {
    padding-top: 36px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .pin {
    width: 24px;
    height: 31px;
  }
  .map.zonguldak {
    padding-top: 150px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.yozgat {
    padding-top: 150px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.yalova {
    padding-top: 170px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.van {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.usak {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.tunceli {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.trabzon {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.tokat {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.tekirdag {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.sivas {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.sirnak {
    padding-top: 150px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.sinop {
    padding-top: 100px;
    padding-left: 50px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.siirt {
    padding-top: 170px;
    padding-left: 50px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.samsun {
    padding-top: 170px;
    padding-left: 50px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.sakarya {
    padding-top: 120px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.rize {
    padding-top: 150px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.osmaniye {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.ordu {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.5;
    pointer-events: none;
  }
  .map.nigde {
    padding-top: 150px;
    padding-left: 200px;
    width: 40%;
    height: 100%;
    scale: 0.9;
    pointer-events: none;
  }
  .map.nevsehir {
    padding-top: 100px;
    padding-left: 200px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.mus {
    padding-top: 120px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.mugla {
    padding-top: 120px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.mersin {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.mardin {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.manisa {
    padding-top: 60px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.malatya {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.kutahya {
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.konya {
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.kocaeli {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.kirsehir {
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.kirklareli {
    padding-top: 150px;
    padding-left: 200px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.kirikkale {
    padding-top: 150px;
    padding-left: 200px;
    width: 40%;
    height: 100%;
    scale: 1.1;
    pointer-events: none;
  }
  .map.kilis {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.5;
    pointer-events: none;
  }
  .map.kayseri {
    width: 40%;
    height: 100%;
    scale: 0.5;
    pointer-events: none;
  }
  .map.kastamonu {
    padding-top: 150px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.kars {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.karaman {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.karabuk {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.kahramanmaras {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.izmir {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.isparta {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.igdir {
    padding-top: 220px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }

  .map.hatay {
    padding-left: 200px;
    padding-top: 130px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.hakkari {
    padding-left: 100px;
    width: 40%;
    height: 100%;
    pointer-events: none;
  }
  .map.gumushane {
    padding-top: 120px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.giresun {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.gaziantep {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.eskisehir {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.erzurum {
    padding-top: 80px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.erzincan {
    padding-left: 50px;
    padding-top: 150px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.elazig {
    padding-left: 50px;
    padding-top: 130px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.edirne {
    padding-left: 100px;
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.duzce {
    padding-left: 100px;
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.diyarbakir {
    padding-left: 100px;
    padding-top: 150px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.denizli {
    padding-left: 100px;
    padding-top: 80px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.bingol {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.bitlis {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.bolu {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.burdur {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.bursa {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.canakkale {
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.cankiri {
    padding-top: 170px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.corum {
    padding-top: 100px;
    padding-left: 70px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.bilecik {
    padding-top: 70px;
    padding-left: 70px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.bayburt {
    padding-top: 150px;
    padding-left: 70px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.batman {
    padding-top: 150px;
    padding-left: 170px;
    width: 40%;
    height: 100%;
    scale: 1;
    pointer-events: none;
  }
  .map.bartin {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.balikesir {
    padding-top: 100px;
    padding-left: 50px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.ankara {
    padding-top: 70px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.istanbul {
    padding-top: 70px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.aydin {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.artvin {
    padding-top: 100px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.ardahan {
    padding-top: 100px;
    padding-left: 150px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.afyonkarahisar {
    padding-left: 150px;
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.antalya {
    padding-top: 150px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.amasya {
    padding-left: 100px;
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.aksaray {
    padding-top: 150px;
    padding-left: 200px;
    width: 40%;
    height: 100%;
    scale: 1;
    pointer-events: none;
  }
  .map.agri {
    padding-top: 200px;
    padding-left: 200px;
    width: 40%;
    height: 100%;
    scale: 1.2;
    pointer-events: none;
  }
  .map.adiyaman {
    padding-top: 150px;
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.adana {
    padding-left: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.sanliurfa {
    padding-left: 80px;
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.6;
    pointer-events: none;
  }
  .map.hakkari {
    padding-top: 100px;
    width: 40%;
    height: 100%;
    scale: 0.7;
    pointer-events: none;
  }
  .notification {
    height: 65%;
    width: 46%;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
  .card {
    display: flex;
    flex-direction: column;
  }

  .province_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 40px;
    right: 40px;
    width: 320px;
    height: 100px;
    background-color: #00abcd;
    border-radius: 8px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -35px;
    right: 25px;
    background-color: #f5f5f5;
    width: 50px;
    height: 50px;
    border-radius: 40px;
  }
  .province_name {
    font-family: "Inter", sans-serif;
    font-size: 30px;
    text-align: left;
    color: #f5f5f5;
    margin-left: 52px;
    font-style: bold;
    font-weight: 700;
    text-transform: capitalize;
  }
  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 20px;
    color: rgb(255, 255, 255, 0.85);
    text-transform: uppercase;
    margin-left: 48px;
    font-style: bold;
    font-weight: 600;
  }
  .information {
    display: flex;
    margin: 0 auto;
    width: 80%;
    height: 110px;
    margin-top: 8px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 26px;
    color: #696969;
  }
  .tag {
    width: 150px;
    height: 40px;
    text-align: center;
    margin-left: 40px;

    background-color: rgb(45, 148, 243, 0.1);
    border: 1px solid #00abcd;
    font-size: 16px;
    color: #0057a8;
    font-family: "Inter", sans-serif;
    font-style: bold;
  }
  .link {
    text-decoration: none;
  }
}
@media (max-width: 374px) {
  .content_container {
    width: 100%;
    height: max-content;
   
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .header {
    text-align: center;    
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: x-large;
  }
  .splide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    align-items: center;
  }
  .container {
    margin: 0 auto;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .notification {
    height: 300px;
    width: 300px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .card {
    display: flex;
    flex-direction: column;
  }

  .province_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    left: -10px;
    width: 170px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
    right: 5px;
    background-color: #f5f5f5;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .province_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #f5f5f5;
    margin-left: 18px;
    font-style: bold;
    font-weight: 700;
  }
  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.85);
    text-transform: uppercase;
    margin-left: 30px;
  }
  .information {
    display: flex;
    margin: 0 auto;
    width: 80%;
    height: 160px;
    margin-top: 8px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 16px;
    color: #696969;
    position: relative;
    top: 25px;
  }
  .tag {
    width: 100px;
    height: 30px;
    text-align: center;
    margin-left: 20px;
    margin-bottom: 16px;
    background-color: rgb(45, 148, 243, 0.1);
    border: 1px solid #00abcd;
    font-size: 14px;
    color: #0057a8;
    font-family: "Inter", sans-serif;
    font-style: bold;
  }
  .link {
    text-decoration: none;
  }
  .pin {
    width: 15px;
    height: 19px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .content_container {
    width: 100%;
    height: max-content;
    
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .header {
    text-align: center;
   
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: x-large;
  }
  .splide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    align-items: center;
  }
  .container {
    margin: 0 auto;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .notification {
    height: 300px;
    width: 340px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .card {
    display: flex;
    flex-direction: column;
  }

  .province_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    left: -15px;
    width: 170px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
    right: 10px;
    background-color: #f5f5f5;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .province_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #f5f5f5;
    margin-left: 12px;
    font-style: bold;
    font-weight: 700;
  }
  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.85);
    text-transform: uppercase;
    margin-left: 30px;
  }
  .information {
    display: flex;
    margin: 0 auto;
    width: 80%;
    height: 160px;
    margin-top: 8px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 16px;
    color: #696969;
    position: relative;
    top: 25px;
  }
  .tag {
    width: 100px;
    height: 30px;
    text-align: center;
    margin-left: 20px;
    margin-bottom: 16px;
    background-color: rgb(45, 148, 243, 0.1);
    border: 1px solid #00abcd;
    font-size: 14px;
    color: #0057a8;
    font-family: "Inter", sans-serif;
    font-style: bold;
  }
  .link {
    text-decoration: none;
  }
  .pin {
    width: 15px;
    height: 19px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .content_container {
    width: 100%;
    height: max-content;
   
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .header {
    text-align: center;
   
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: x-large;
  }
  .splide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    align-items: center;
  }
  .container {
    margin: 0 auto;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .notification {
    height: 300px;
    width: 370px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .card {
    display: flex;
    flex-direction: column;
  }

  .province_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 50px;
    left: -25px;
    width: 180px;
    height: 50px;
    background-color: #00abcd;
    border-radius: 8px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 10px;
    right: 15px;
    background-color: #f5f5f5;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  .province_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: #f5f5f5;
    margin-left: 12px;
    font-style: bold;
    font-weight: 700;
  }
  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.85);
    text-transform: uppercase;
    margin-left: 35px;
  }
  .information {
    display: flex;
    margin: 0 auto;
    width: 80%;
    height: 160px;
    margin-top: 8px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 16px;
    color: #696969;
    position: relative;
    top: 25px;
  }
  .tag {
    width: 100px;
    height: 30px;
    text-align: center;
    margin-left: 20px;
    margin-bottom: 16px;
    background-color: rgb(45, 148, 243, 0.1);
    border: 1px solid #00abcd;
    font-size: 14px;
    color: #0057a8;
    font-family: "Inter", sans-serif;
    font-style: bold;
  }
  .link {
    text-decoration: none;
  }
  .pin {
    width: 15px;
    height: 19px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .content_container {
    width: 100%;
    height: max-content;
    
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .header {
    text-align: center;
    
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
    font-size: x-large;
  }
  .splide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: fit-content;
    align-items: center;
  }
  .container {
    margin: 0 auto;
    height: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .map {
    width: 100%;
    height: 300px;
  }
  .notification {
    height: 300px;
    width: 540px;
    border-radius: 8px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .card {
    display: flex;
    flex-direction: column;
  }

  .province_area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 30px;
    left: -30px;
    width: 200px;
    height: 60px;
    background-color: #00abcd;
    border-radius: 8px;
  }
  .location {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -20px;
    right: 15px;
    background-color: #f5f5f5;
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  .province_name {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    color: #f5f5f5;
    margin-left: 30px;
    font-style: bold;
    font-weight: 700;
  }
  .district_name {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: rgb(255, 255, 255, 0.85);
    text-transform: uppercase;
    margin-left: 40px;
  }
  .information {
    display: flex;
    margin: 0 auto;
    width: 80%;
    height: 150px;
    margin-top: 8px;
    justify-content: center;
    overflow: hidden;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: italic;
    font-size: 16px;
    color: #696969;
    position: relative;
    top: 25px;
  }
  .tag {
    width: 100px;
    height: 30px;
    text-align: center;
    margin-left: 20px;
    background-color: rgb(45, 148, 243, 0.1);
    border: 1px solid #00abcd;
    font-size: 14px;
    color: #0057a8;
    font-family: "Inter", sans-serif;
    font-style: bold;
    position: relative;
    top: -15px;
  }
  .link {
    text-decoration: none;
  }
  .pin {
    width: 18px;
    height: 25px;
  }
}
@media only screen and (max-width: 424px) {
  .map.zonguldak {
    margin-left: 25%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.yozgat {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.yalova {
    padding-top: 20%;
    pointer-events: none;
  }
  .map.kahramanmaras {
    margin-left: 25%;
    pointer-events: none;
  }
  .map.ankara {
    margin-left: 15%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.van {
    margin-left: 45%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.usak {
    margin-left: 15%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.tunceli {
    margin-left: 10%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.trabzon {
    margin-left: 10%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.tokat {
    margin-left: 5%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.tekirdag {
    margin-left: 15%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.sivas {
    margin-left: 25%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.sirnak {
    padding-top: 20%;
    pointer-events: none;
  }
  .map.sinop {
    margin-left: 35%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.siirt {
    margin-left: 25%;
    padding-top: 30%;
    pointer-events: none;
  }
  .map.samsun {
    margin-left: 5%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.sakarya {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.rize {
    margin-left: 25%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.osmaniye {
    margin-left: 30%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.ordu {
    padding-top: 5%;
    pointer-events: none;
  }
  .map.nigde {
    margin-left: 40%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.nevsehir {
    margin-left: 40%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mus {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mugla {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mersin {
    margin-left: 10%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mardin {
    padding-top: 15%;
    pointer-events: none;
  }
  .map.manisa {
    padding-top: 5%;
    pointer-events: none;
  }
  .map.malatya {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.kutahya {
    margin-left: 10%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.konya {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.kocaeli {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirsehir {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirsehir {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirklareli {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirikkale {
    margin-left: 50%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kilis {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kayseri {
    margin-left: 10%;
    pointer-events: none;
  }
  .map.kastamonu {
    margin-left: 15%;
    pointer-events: none;
  }
  .map.kars {
    margin-left: 25%;
    pointer-events: none;
  }
  .map.karaman {
    padding-top: 10%;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.karabuk {
    padding-top: 10%;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.izmir {
    padding-top: 10%;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.isparta {
    padding-top: 10%;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.igdir {
    padding-top: 30%;
    pointer-events: none;
  }
  .map.hatay {
    margin-left: 50%;
    pointer-events: none;
  }
  .map.hakkari {
    margin-left: 20%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.gumushane {
    margin-left: 30%;
    padding-top: 15%;
    pointer-events: none;
  }
  .map.giresun {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.gaziantep {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.eskisehir {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.erzurum {
    margin-left: 25%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.erzincan {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.elazig {
    margin-left: 5%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.edirne {
    margin-left: 45%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.duzce {
    margin-left: 15%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.diyarbakir {
    margin-left: 15%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.denizli {
    margin-left: 35%;
    pointer-events: none;
  }
  .map.corum {
    margin-left: 35%;
    pointer-events: none;
  }
  .map.cankiri {
    margin-left: 25%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bursa {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.burdur {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bolu {
    margin-left: 5%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bitlis {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bingol {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bilecik {
    margin-left: 40%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bayburt {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.batman {
    margin-left: 60%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bartin {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.balikesir {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.aydin {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.artvin {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.ardahan {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.antalya {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.amasya {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.aksaray {
    margin-left: 40%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.agri {
    margin-left: 40%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.afyonkarahisar {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.adiyaman {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.adana {
    margin-left: 30%;   
    pointer-events: none;
  }
  .map.sanliurfa {
    margin-left: 10%;   
    pointer-events: none;
  }
}

@media only screen and (min-width: 425px) and (max-width:767px) {
  .map.canakkale{
    scale: 0.8;
  }
  .map.zonguldak {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.yozgat {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.yalova {
    padding-top: 20%;
    pointer-events: none;
  }

  .map.kahramanmaras {
    margin-left: 25%;
    scale: 0.9;
    pointer-events: none;
  }
  .map.ankara {
    margin-left: 15%;
    
    pointer-events: none;
  }
  .map.van {
    margin-left: 45%;
    
    pointer-events: none;
  }
  .map.usak {
    margin-left: 15%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.tunceli {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.trabzon {
    margin-left: 10%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.tokat {
    margin-left: 5%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.tekirdag {
    margin-left: 15%;
   
    pointer-events: none;
  }
  .map.sivas {
    margin-left: 25%;
    
    pointer-events: none;
  }
  .map.sirnak {
    padding-top: 20%;
    pointer-events: none;
  }
  .map.sinop {
    margin-left: 35%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.siirt {
    margin-left: 25%;
    padding-top: 30%;
    pointer-events: none;
  }
  .map.samsun {
    margin-left: 5%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.sakarya {
    margin-left: 30%;
   
    pointer-events: none;
  }
  .map.rize {
    margin-left: 25%;
   
    pointer-events: none;
  }
  .map.osmaniye {
    margin-left: 40%;
    
    pointer-events: none;
  }
  .map.ordu {
    padding-top: 5%;
    pointer-events: none;
  }
  .map.nigde {
    margin-left: 40%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.nevsehir {
    margin-left: 40%;
   
    pointer-events: none;
  }
  .map.mus {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mugla {
    margin-left: 20%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mersin {
    margin-left: 10%;
    padding-top: 5%;
    pointer-events: none;
  }
  .map.mardin {
    padding-top: 15%;
    pointer-events: none;
  }
  .map.manisa {
   scale: 0.8;
    pointer-events: none;
  }
  .map.malatya {
    margin-left: 20%;
   scale: 0.9;
    pointer-events: none;
  }
  .map.kutahya {
    margin-left: 10%;
    scale: 0.9;
    pointer-events: none;
  }
  .map.konya {
    margin-left: 20%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.kocaeli {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirsehir {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirsehir {
    margin-left: 20%;
   
    pointer-events: none;
  }
  .map.kirklareli {
    margin-left: 20%;
    
    pointer-events: none;
  }
  .map.kirikkale {
    margin-left: 60%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kilis {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kayseri {
    margin-left: 10%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.kastamonu {
    margin-left: 15%;
    pointer-events: none;
  }
  .map.kars {
    margin-left: 25%;
    pointer-events: none;
  }
  .map.karaman {
    padding-top: 10%;
    margin-left: 30%;
    pointer-events: none;
  }
  .map.karabuk {
    padding-top: 10%;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.izmir {
   
    margin-left: 25%;
    pointer-events: none;
  }
  .map.isparta {
   
    margin-left: 25%;
    pointer-events: none;
  }
  .map.igdir {
    padding-top: 20%;
    pointer-events: none;
  }
  .map.hatay {
    margin-left: 50%;
    pointer-events: none;
  }
  .map.hakkari {
    margin-left: 20%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.gumushane {
    margin-left: 30%;
   
    pointer-events: none;
  }
  .map.giresun {
    margin-left: 30%;
    
    pointer-events: none;
  }
  .map.gaziantep {
   
    pointer-events: none;
  }
  .map.eskisehir {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.erzurum {
    margin-left: 25%;
    
    pointer-events: none;
  }
  .map.erzincan {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.elazig {
    margin-left: 5%;
    
    pointer-events: none;
  }
  .map.edirne {
    margin-left: 45%;
    
    pointer-events: none;
  }
  .map.duzce {
    margin-left: 15%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.diyarbakir {
    margin-left: 15%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.denizli {
    margin-left: 35%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.corum {
    margin-left: 35%;
    pointer-events: none;
  }
  .map.cankiri {
    margin-left: 25%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bursa {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.burdur {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bolu {
    margin-left: 5%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bitlis {
    margin-left: 20%;
   
    pointer-events: none;
  }
  .map.bingol {
    margin-left: 20%;
    
    pointer-events: none;
  }
  .map.bilecik {
    margin-left: 40%;
    
    pointer-events: none;
  }
  .map.bayburt {
    margin-left: 40%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.batman {
    margin-left: 70%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.bartin {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.balikesir {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.aydin {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.artvin {
    margin-left: 30%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.ardahan {
    margin-left: 30%;
    
    pointer-events: none;
  }
  .map.antalya {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.amasya {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.aksaray {
    margin-left: 40%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.agri {
    margin-left: 40%;
    padding-top: 20%;
    pointer-events: none;
  }
  .map.afyonkarahisar {
    margin-left: 20%;
    
    pointer-events: none;
  }
  .map.adiyaman {
    margin-left: 10%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.adana {
    margin-left: 30%; 
    scale: 0.9; 
    pointer-events: none;
  }
  .map.sanliurfa {
    margin-left: 10%;   
    pointer-events: none;
  }
}

@media only screen and (min-width: 768px) and (max-width:1023px) {
  .map.canakkale{
    scale: 0.6;
    position: relative;
    top: -20px;
  }
  .map.zonguldak {
    margin-left: 30%;
   
    pointer-events: none;
  }
  .map.yozgat {
    margin-left: 20%;
    scale: 0.9;
    pointer-events: none;
  }
  .map.yalova {
    
    pointer-events: none;
  }

  .map.kahramanmaras {
    margin-left: 25%;
    scale: 0.6;
    position: relative;
    top: -30px;
    pointer-events: none;
  }
  .map.ankara {
    margin-left: 15%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.van {
    margin-left: 30%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.usak {
    margin-left: 15%;
    
    pointer-events: none;
  }
  .map.tunceli {
    margin-left: 10%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.trabzon {
    margin-left: 10%;
    
    pointer-events: none;
  }
  .map.tokat {
    margin-left: 5%;
    
    pointer-events: none;
  }
  .map.tekirdag {
    margin-left: 15%;
   scale: 0.7;
   position: relative;
   top: -30px;
    pointer-events: none;
  }
  .map.sivas {
    margin-left: 25%;
    scale: 0.8;
    position: relative;
    top: -20px;
    pointer-events: none;
  }
  .map.sirnak {
    
    pointer-events: none;
  }
  .map.sinop {
    margin-left: 35%;
   
    pointer-events: none;
  }
  .map.siirt {
    margin-left: 25%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.samsun {
    margin-left: 5%;
   
    pointer-events: none;
  }
  .map.sakarya {
    margin-left: 20%;
   scale: 0.7;
   position: relative;
   top: -20px;
    pointer-events: none;
  }
  .map.rize {
    margin-left: 25%;
   scale: 0.8;
   position: relative;
   top: -20px;
    pointer-events: none;
  }
  .map.osmaniye {
    margin-left: 30%;
    scale: 0.7;
    position: relative;
    top: -20px;
    pointer-events: none;
  }
  .map.ordu {
    scale: 0.7;
    pointer-events: none;
  }
  .map.nigde {
    margin-left: 40%;
    position: relative;
    top: -10px;
    scale: 0.9;
    pointer-events: none;
  }
  .map.nevsehir {
    margin-left: 40%;
   scale: 0.8;
   position: relative;
   top: -30px;
    pointer-events: none;
  }
  .map.mus {
    margin-left: 20%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.mugla {
    margin-left: 20%;
   scale: 0.9;
    pointer-events: none;
  }
  .map.mersin {
    margin-left: 10%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.mardin {
    scale: 0.8;
    pointer-events: none;
  }
  .map.manisa {
   scale: 0.6;
   position: relative;
   top: -40px;
    pointer-events: none;
  }
  .map.malatya {
    margin-left: 20%;
   scale: 0.7;
    pointer-events: none;
  }
  .map.kutahya {
    margin-left: 10%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.konya {
    margin-left: 20%;
   scale: 0.6;
   position: relative;
   top: -30px;
    pointer-events: none;
  }
  .map.kocaeli {
    margin-left: 20%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.kirsehir {
    margin-left: 20%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.kirsehir {
    margin-left: 10%;
   scale: 0.7;
   position: relative;
   top: -50px;
    pointer-events: none;
  }
  .map.kirklareli {
    margin-left: 20%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.kirikkale {
    margin-left: 60%;
    
    pointer-events: none;
  }
  .map.kilis {
    scale: 0.8;
    pointer-events: none;
  }
  .map.kayseri {
    margin-left: 10%;
    scale: 0.6;
    position: relative;
    top: -40px;
    pointer-events: none;
  }
  .map.kastamonu {
    margin-left: 15%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.kars {
    scale: 0.7;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.karaman {
    scale: 0.8;
    margin-left: 30%;
    pointer-events: none;
  }
  .map.karabuk {
    scale: 0.8;
    margin-left: 25%;
    pointer-events: none;
  }
  .map.izmir {
   scale: 0.7;
    margin-left: 20%;
    pointer-events: none;
  }
  .map.isparta {
   scale: 0.7;
   position: relative;
   top: -20px;
    margin-left: 10%;
    pointer-events: none;
  }
  .map.igdir {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.hatay {
    margin-left: 40%;
    scale: 0.7;
    position: relative;
    top: -20px;
    pointer-events: none;
  }
  .map.hakkari {
    margin-left: 20%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.gumushane {
    margin-left: 25%;
   scale: 0.7;
    pointer-events: none;
  }
  .map.giresun {
    margin-left: 30%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.gaziantep {
   scale: 0.8;
    pointer-events: none;
  }
  .map.eskisehir {
    margin-left: 5%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.erzurum {
    margin-left: 10%;
    scale: 0.7;
    position: relative;
    top: -30px;
    pointer-events: none;
  }
  .map.erzincan {
    margin-left: 10%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.elazig {
    margin-left: 5%;
    scale: 0.7;
    pointer-events: none;
  }
  .map.edirne {
    margin-left: 35%;
    scale: 0.7;
    position: relative;
    top: -30px;
    pointer-events: none;
  }
  .map.duzce {
    margin-left: 15%;
   scale: 0.7;
    pointer-events: none;
  }
  .map.diyarbakir {
    margin-left: 15%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.denizli {
    margin-left: 25%;
    scale: 0.6;
    position: relative;
    top: -40px;
    pointer-events: none;
  }
  .map.corum {
    margin-left: 35%;
    scale: 0.7;
    position: relative;
    top: -40px;
    pointer-events: none;
  }
  .map.cankiri {
    margin-left: 25%;
    
    pointer-events: none;
  }
  .map.bursa {
    margin-left: 10%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.burdur {
    margin-left: 20%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.bolu {
    margin-left: 5%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.bitlis {
    margin-left: 20%;
   scale: 0.7;
    pointer-events: none;
  }
  .map.bingol {
    margin-left: 20%;
    scale: 0.7;
    position: relative;
    top: -20px;
    pointer-events: none;
  }
  .map.bilecik {
    margin-left: 40%;
    scale: 0.8;
    position: relative;
    top: -30px;
    pointer-events: none;
  }
  .map.bayburt {
    margin-left: 40%;
    
    pointer-events: none;
  }
  .map.batman {
    margin-left: 60%;
   scale: 0.9;
    pointer-events: none;
  }
  .map.bartin {
    margin-left: 20%;
   scale: 0.7;
    pointer-events: none;
  }
  .map.balikesir {
    margin-left: 20%;
   scale: 0.8;
    pointer-events: none;
  }
  .map.aydin {
   
    pointer-events: none;
  }
  .map.artvin {
    margin-left: 30%;
   scale: 0.8;
   position: relative;
   top: -20px;
    pointer-events: none;
  }
  .map.ardahan {
    margin-left: 30%;
    scale: 0.8;
    position: relative;
    top: -20px;
    pointer-events: none;
  }
  .map.antalya {
    padding-top: 10%;
    pointer-events: none;
  }
  .map.amasya {
    margin-left: 20%;
    scale: 0.8;
    pointer-events: none;
  }
  .map.aksaray {
    margin-left: 40%;
   scale: 0.9;
    pointer-events: none;
  }
  .map.agri {
    margin-left: 40%;
    padding-top: 10%;
    pointer-events: none;
  }
  .map.afyonkarahisar {
    margin-left: 20%;
    scale: 0.7;
    position: relative;
    top: -20px;
    pointer-events: none;
  }
  .map.adiyaman {
    margin-left: 10%;
    
    pointer-events: none;
  }
  .map.adana {
    margin-left: 30%; 
    position: relative;
    top: -50px;
    scale: 0.6; 
    pointer-events: none;
  }
  .map.sanliurfa {
    margin-left: 10%;   
    position: relative;
    top: -30px;
    pointer-events: none;
    scale: 0.7;
  }
}
