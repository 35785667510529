.hr{
  width: 64px;
  height: 5px;
  margin: 0 auto;
  background-color: #00abcd;
  border-radius: 8px;
  margin-top: 8px;
}
.main_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  height: 100vh;
  
  padding-top: 82px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.header {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #282828;
  text-transform: capitalize;
}
.iframe {
  border: none;
  width: 100%;
  height: 352px;
}

.splide_main {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  width: 50%;
  height: 352px;
}
@media only screen and (max-width: 374px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container {
    height: 100vh;
    padding-top: 16px;
 
    height: max-content;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border: none;
    height: 152px;
    width: 300px;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    width: 300px;
    height: 152px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 768px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container {
    height: 100vh;
    padding-top: 16px;
   
    height: max-content;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border: none;
    height: 352px;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    width: 95%;
    height: 352px;
  }
}
