@media (min-width: 768px) {
  .container {
    background-color: #101410;
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    justify-items: center;
    gap: 32px;
    color: #f8f9fa;
    padding: 16px;
    font-family: "Inter", sans-serif;
    text-align: center;
    cursor: pointer;
  }
  .links {
    display: flex;
    justify-content: center;
    gap: 32px;
  }
  .div {
    display: flex;
    flex-direction: column;
  }
  .header {
    text-align: left;
    padding-top: 16px;
  }

  .link {
    color: #aaaaaa;
    text-decoration: none;
    padding-top: 8px;
    text-align: left;
  }
  .link:hover {
    color: aliceblue;
  }

  .logo {
    position: relative;
    top: -54px;
    width: 120px;
    margin-bottom: 16px;
  }
  .info {
    font-family: "Inter", sans-serif;
    text-align: center;
    color: aliceblue;
    background-color: #101410;
    font-size: 14px;
    width: 100%;
    padding-bottom: 32px;
  }
  .icon {
    padding-top: 8px;
    text-align: left;
    width: 50px;
    height: 50px;
  }
  .icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
  }
}
@media (max-width: 767px) {
  .container {
    background-color: #101410;
    width: 100%;
    height: 350px;
    display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
    color: #f8f9fa;
   
    font-family: "Inter", sans-serif;
    text-align: center;
    cursor: pointer;
  }
  .links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
  .div {
    margin-top: 16px;
    width: max-content;
    height: max-content;
  }
  .link {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #aaaaaa;
    text-decoration: none;
    font-size: small;
    padding-top: 8px;
    text-align: left;
  }
  .link:hover {
    color: aliceblue;
  }
  .header {
    font-size: small;
    margin-bottom: 8px;
  }
  .logo {
    margin: 0 auto;    
    width: 80px;
    margin-top: 32px;
    
  }
  .info {
    font-family: "Inter", sans-serif;
    text-align: center;
    color: aliceblue;
    background-color: #101410;
    font-size: 12px;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .icon {
    padding-top: 8px;
    text-align: left;
    width: 40px;
    height: 40px;
  }
  .icons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    width: max-content;
    height: max-content;
  }
}
