.hr{
  width: 64px;
  height: 5px;
  margin: 0 auto;
  background-color: #00abcd;
  border-radius: 8px;
  margin-top: 8px;
}
.main_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  height: 100vh;
  padding-top: 82px;
 
  margin-top: 16px;
  margin-bottom: 16px;
}
.splide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe {
  border-radius: 16px;
  width: 960px;
  height: 540px;
}
.header {
  text-align: center;

  font-family: "Inter", sans-serif;
  font-style: normal;
  color: #61666f;
  text-transform: capitalize;
}

.splide_main {
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
  border-radius: 16px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 960px;
}

@media screen and (max-width: 374px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container {
    height: 100vh;
    padding-top: 16px;
   
    height: max-content;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border-radius: 16px;
    width: 300px;
    height: 160px;
  }
  .header {
    text-align: center;

    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
  }
}
@media only screen and (min-width: 375px) and (max-width: 425px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container {
    height: 100vh;
    padding-top: 16px;
   
    height: max-content;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border-radius: 16px;
    width: 365px;
    height: 160px;
  }
  .header {
    text-align: center;

    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 365px;
  }
}
@media only screen and (min-width: 425px) and (max-width: 768px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container {
    height: 100vh;
    padding-top: 16px;
    background-color: #f5f7fa;
    height: max-content;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border-radius: 16px;
    width: 100%;
    height: 260px;
  }
  .header {
    text-align: center;

    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 260px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .container {
    height: 100vh;
    padding-top: 16px;
    
    height: max-content;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border-radius: 16px;
    width: 100%;
    height: 500px;
  }
  .header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 500px;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .main_container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container {
    height: 100vh;
    padding-top: 82px;
    
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .splide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iframe {
    border-radius: 16px;
    width: 960px;
    height: 540px;
  }
  .header {
    text-align: center;
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #61666f;
    text-transform: capitalize;
  }

  .splide_main {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.6);
    border-radius: 16px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 960px;
  }
}
