.container {
  margin-top: 80px;
  width: 100%;
  height: max-content;
}
.header_area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 48px;
}
.header {
  font-family: "Inter", sans-serif;
  text-align: center;
  margin-top: 16px;
  font-weight: 400;
}
.header1 {
  font-family: "Inter", sans-serif;
  text-align: center;
  margin-top: 32px;
  font-weight: 500;
  font-size: xx-large;
}
.mail_area {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
}
.mail {
  position: relative;
  left: 55px;
  width: 400px;
  height: 50px;
  border-radius: 32px;
  margin-top: 16px;
  font-family: "Inter", sans-serif;
  border: 2px solid black;
  padding-left: 16px;
}
.mail::placeholder {
  color: black;
  font-family: "Inter", sans-serif;
}
.button {
  position: relative;
  top: 7.5px;
  right: 50px;
  width: 100px;
  height: 40px;
  background-color: #00abcd;
  color: white;
  border: none;
  border-radius: 32px;
}

@media only screen and (max-width: 374px) {
  .container {
    margin-top: 80px;
    width: 100%;
    height: max-content;
  }
  .header_area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
  }
  .header {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
  }
  .header1 {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-top: 0px;
    font-weight: 500;
    font-size: 20px;
  }
  .mail_area {
    padding-bottom: 16px;
  }
  .mail {
    position: relative;
    left: 35px;

    width: 320px;
    height: 50px;
    border-radius: 32px;
    margin-top: 16px;
    font-family: "Inter", sans-serif;
    border: 2px solid black;
  }
  .mail::placeholder {
    color: black;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .button {
    position: relative;
    top: 7.5px;
    right: 35px;
    width: 80px;
    height: 35px;
    background-color: #00abcd;
    color: white;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    border-radius: 32px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .container {
    margin-top: 80px;
    width: 100%;
    height: max-content;
  }
  .header_area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
  }
  .header {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
  }
  .header1 {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-top: 0px;
    font-weight: 500;
    font-size: 20px;
  }
  .mail_area {
    padding-bottom: 16px;
  }
  .mail {
    position: relative;
    left: 35px;

    width: 320px;
    height: 50px;
    border-radius: 32px;
    margin-top: 16px;
    font-family: "Inter", sans-serif;
    border: 2px solid black;
  }
  .mail::placeholder {
    color: black;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .button {
    position: relative;
    top: 7.5px;
    right: 45px;
    width: 80px;
    height: 35px;
    background-color: #00abcd;
    color: white;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    border-radius: 32px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .container {
    margin-top: 80px;
    width: 100%;
    height: max-content;
  }
  .header_area {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0px;
  }
  .header {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-top: 16px;
    font-weight: 400;
    font-size: 15px;
  }
  .header1 {
    font-family: "Inter", sans-serif;
    text-align: center;
    margin-top: 0px;
    font-weight: 500;
    font-size: 24px;
  }
  .mail_area {
    padding-bottom: 16px;
  }
  .mail {
    position: relative;
    left: 45px;

    width: 320px;
    height: 50px;
    border-radius: 32px;
    margin-top: 16px;
    font-family: "Inter", sans-serif;
    border: 2px solid black;
  }
  .mail::placeholder {
    color: black;
    font-family: "Inter", sans-serif;
    font-size: 12px;
  }
  .button {
    position: relative;
    top: 7.5px;
    right: 45px;
    width: 80px;
    height: 35px;
    background-color: #00abcd;
    color: white;
    border: none;
    font-family: "Inter", sans-serif;
    font-size: 10px;
    border-radius: 32px;
  }
}
